import { ExistingNumberContext } from "contexts/FormsContext";
import React, { useContext } from "react";
import { useEffect, useState } from "react";
import PlanRates from "./PlanRates";
import "pages/order/assets/css/plans.scoped.css";
import PlanOptions from "./plans/PlanOptions";
import Category from "./plans/Category";
import { useDispatch } from "react-redux";
import { getPlansByCategoryWithRates } from "redux/actions/OrderAction";

const PlansCategory = ({ setStep, activeStep, setPlanOption = () => { } }) => {
	const dispatch = useDispatch();
	const { ExistingNumberData, setExistingNumberData } = useContext(
		ExistingNumberContext
	);
	const planForCountry = ExistingNumberData.first_number.countryId || 230;
	const [activeSteps, setActiveSteps] = useState(1);

	const [planRates, setPlanRates] = useState({
		open: 0,
		data: []
	});

	const steps = [
		{
			step: 1,
			cat_name: "Select Category",
			sub_heading: "Please select a category to view the available plan options"
		},
		{
			step: 2,
			cat_name: "Select Plan Option",
			sub_heading: "PLEASE SELECT A PLAN BELOW FOR THE SELECTED CATEGORY"
		}
	];

	useEffect(() => {
		console.log("in use effectttttt");
		// setExistingNumberData({ ...ExistingNumberData, error: [], ready: false });

		if (
			ExistingNumberData.plan_rate_id != "" &&
			ExistingNumberData.plan != ""
		) {
			setExistingNumberData({ ...ExistingNumberData, error: [], ready: true });
		} else if (
			ExistingNumberData.plan_rate_id == "" &&
			ExistingNumberData.plan == "" &&
			ExistingNumberData.planChange
		) {
			setExistingNumberData({
				...ExistingNumberData,
				error: ["Plan is not selected"],
				ready: false
			});
		} else {
		}

		let filterNumber = "";
		switch (true) {
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "YES":
				filterNumber = "fax";
				break;
			case ExistingNumberData.is_this_a_mobile_number === "YES":
				filterNumber = "cell phone";
				break;
			case ExistingNumberData.first_number?.number_type === "TOLL_FREE":
				filterNumber = "toll free";
				break;
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "NO" &&
				ExistingNumberData.is_this_a_mobile_number === "NO":
				filterNumber = "regular landline";
				break;
			default:
				filterNumber = "";
		}
		const data = {
			country_id: planForCountry,
			phone_type: filterNumber
		};
		
		dispatch(getPlansByCategoryWithRates(data));
	}, [
		ExistingNumberData?.planCategory,
		ExistingNumberData.use_number_only_to_send_receive_faxes,
		ExistingNumberData.is_this_a_mobile_number,
		ExistingNumberData.first_number?.number_type,
		ExistingNumberData.is_this_a_mobile_number
	]);

	return (
		<div className="custom_width">
			<div
				className={
					"step new_plans_box" + (activeSteps === 2 ? " choose-plan" : "")
				}
			>
				{
					<PlanRates
						show={planRates.open}
						data={planRates.data}
						setShow={() => setPlanRates({ open: false, data: [] })}
					/>
				}
				<h1 className="lg-heading text-center font-46">Our Plans</h1>
				{/* STEPPER */}
				<div className="op-stepper d-flex justify-content-between">
					{steps?.map((row, index) => {
						return (
							<>
								{steps.length === 0 ? (
									<></>
								) : (
									<>
										<div
											class={
												"op-step text-center step" +
												row?.step +
												(row?.step <= activeSteps ? " active" : "")
											}
										// onClick={() => setActiveSteps(row.step)}
										>
											<label className="d-block" htmlFor={row?.cat_name}>
												{row?.cat_name}
											</label>
											<span className="counter">{row?.step}</span>
										</div>
									</>
								)}
							</>
						);
					})}
				</div>
				{/* STEPPER END */}

				<h5 className="mt-4 gray-heading text-center text-uppercase ">
					{steps.filter(row => row.step == activeSteps)[0]?.sub_heading}
				</h5>
				{activeSteps === 1 ? (
					<Category
						activeSteps={activeSteps}
						setActiveSteps={e => setActiveSteps(e)}
					/>
				) : (
					<PlanOptions setStep={setStep} activeStep={activeStep} />
				)}
			</div>
		</div>
	);
};

export default PlansCategory;
