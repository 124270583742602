import React, { useState, useEffect, useRef, useContext } from 'react';
import MultiCarousel from '../MultiCarousel'
import 'pages/order/assets/css/plans.scoped.css';
import { useDispatch, useSelector } from 'react-redux';
import FormFooter from '../FormFooter';
import { ExistingNumberContext } from 'contexts/FormsContext';
import { getPlansByCategory } from 'redux/actions/OrderAction';
import MiniLoader from 'components/Loader/MiniLoader';
import { getCarriers } from 'redux/actions/CarrierAction';

const PlanOptions = ({ setStep, activeStep }) => {

	const [loading, setLoading] = useState(true);
	const isPeriodSet = useRef(false);
	const dispatch = useDispatch()
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const planForCountry = ExistingNumberData.first_number.countryId || 230;

	const [periodWithType, setPeriodWithType] = useState([]);

	const [selectedCategory, setSelectedCategory] = useState(ExistingNumberData?.planCategory || '')
	const { planCategoryList } = useSelector(state => state?.orders)
	const [selectedRate, setSelectedRate] = useState(ExistingNumberData.plan_rate_id || ''); //selected rate id
	const [selectedRateDetails, setSelectedRateDetails] = useState(ExistingNumberData?.plan_details);
	const plansWithRates = useSelector(state => state.orders?.planByCategory);
	const [ratesList, setRatesList] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState(0); //selected rate id
	
	const imageArray = ["cat_one_icon", "cat_two_icon", "cat_three_icon", "cat_four_icon"];
	const [activeButton, setActiveButton] = useState('toggle-button1');

	var rates = [];
	const handleSelectedCategoryChange = (e) => {
		setRatesList([])
		setSelectedCategory(e);

		setExistingNumberData({
			...ExistingNumberData,
			'planCategory': e,
			'planChange': false,
			'plan_voice_email': "",
			'plan_text_email': "",
			'plan_forward_number': "",
			'plan_forward_number_carrier': "",
			'plan_forward_number_country_id': "",
		})
		let filterNumber = ""

		switch (true) {
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "YES":
				filterNumber = 'fax';
				break;
			case ExistingNumberData.is_this_a_mobile_number === "YES":
				filterNumber = 'cell phone';
				break;
			case ExistingNumberData.first_number?.number_type === "TOLL_FREE":
				filterNumber = 'toll free';
				break;
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "NO" && ExistingNumberData.is_this_a_mobile_number === "NO":
				filterNumber = 'regular landline';
				break;
			default:
				filterNumber = '';
				break;
		}
		const payload = {
			'country_id': planForCountry,
			'category_id': e,
			'phone_type': filterNumber,
		};

		dispatch(getPlansByCategory(payload));
		setSelectedMonth(0);
		setSelectedRateDetails({});
	}

	const handleChange = ({ target: { dataset, value } }) => {
		setSelectedRate(value)
		setExistingNumberData({
			...ExistingNumberData,
			'plan_rate_id': value,
			'plan': dataset?.planId,
			'planChange': true,
			'plan_details': plansWithRates.filter((data) => data.id == value)[0]

		})
		setSelectedRateDetails(plansWithRates.filter((data) => data.id == value)[0]);
	}

	const handleClick = (id, month) => {
		setActiveButton(id);
		if (month === "All") {
			setRatesList(plansWithRates);
		} else {
			const newMonthIndex = month;
			console.log(newMonthIndex)
			if (newMonthIndex >= 0) {
				const filteredRates = plansWithRates.filter(x => x.period == month);
				if (filteredRates.length != 0) {
					setRatesList(filteredRates);
				}
			}
		}
	};


	useEffect(() => {
		setRatesList(plansWithRates)
		setLoading(false);

		if (plansWithRates.length > 0) {

			const uniqueValues = plansWithRates
				?.filter((item, index, self) =>
					index === self.findIndex((t) => t.period === item.period && t.period_type === item.period_type)
				)
				.map((item) => ({ period: item.period, period_type: item.period_type }));

			const modifiedValues = [{ period: 'All', period_type: '' }, ...uniqueValues];

			modifiedValues.sort((a, b) => {
				if (a.period === 'all') return -1; // Ensure 'all' is at the beginning
				if (b.period === 'all') return 1;
				return a.period - b.period;
			});

			setPeriodWithType(modifiedValues);
			const defaultSelectedFilter = modifiedValues.findIndex(item => item.period == 12 && item.period_type == 'month')
			setActiveButton(`toggle-button${defaultSelectedFilter + 1}`);
			handleClick(`toggle-button${defaultSelectedFilter + 1}`, 12)
		}

		// const { plan } = ExistingNumberData;
		// if (!plan) {
		// 	setExistingNumberData({ ...ExistingNumberData, 'ready': false, 'error': ['Empty Id'] })
		// } else if (plan) {
		// 	setExistingNumberData({ ...ExistingNumberData, 'ready': true, 'error': [] })
		// }

		return () => {
			isPeriodSet.current = false;
			setLoading(true)
		}

	}, [ExistingNumberData.plan, ExistingNumberData.plan_rate_id, plansWithRates])

	return (
		<>
			{
				loading ?
					<MiniLoader /> :
					<>
						<div className="row  row-cols-1 row-cols-md-1 mb-3 cat_options">
							<div className="col">
								<h5 className="input-label mb-2 mt-4 text-uppercase text-left">Selected plan category</h5>
								<div className="select-img d-flex ">
									<div className="img-box">
										<img className="category-icon mt-3 mb-4" src="/images/phone.png" alt="" height={34} />
									</div>

									<select className='sp-heading-new form-select form-select-lg mb-3' onChange={(e) => handleSelectedCategoryChange(e.target.value)} value={selectedCategory}>
										{
											planCategoryList?.map((category, index) => {
												return <option key={index} value={category?.id}>{category?.name}</option>
											})
										}
									</select>
								</div>
							</div>
							{ratesList?.length > 0 ? <div className="col d-flex justify-content-center mt-3 mb-4">
								<div className='roller-month d-flex flex-column  align-items-md-center'>
									<div className="tri-state-toggle">
										{
											periodWithType.map((per, index) => (

												<button
													key={index}
													className={`tri-state-toggle-button ${activeButton === `toggle-button${index + 1}` ? 'active' : ''}`}
													id={`toggle-button${index}`}
													onClick={() => handleClick(`toggle-button${index + 1}`, per.period)}
												>
													{activeButton === `toggle-button${index + 1}` && (
														<span><img src="images/plan_select_icon.png" alt="icon" className='img-fluid' /></span>
													)}
													<span className='ps-2'>{per.period + ' ' + per.period_type}</span>
												</button>
											))
										}
									</div>

								</div>
							</div> : <></>
							}
							{
								ratesList?.length > 0 ?
									ratesList.map((row, index) => {
										const imageIndex = index % imageArray.length;
										var items = (
											<>
												<div className='col p- mb-4 selecotr-item new_plan_cards' >
													<input value={row.id} onChange={handleChange} type="radio" id={'radio' + rates.length} name='' className='selector-item_radio' data-plan-id={row.plan_id} checked={selectedRate == row.id} />
													<label htmlFor={'radio' + rates.length} className={(selectedRate == row.id) ? 'selector-item_label checked' : "selector-item_label"}>
														<i className="fa fa-check selected-check" aria-hidden="true"></i>
														<div className="option-box">

															{/* <h2 className='p-3 mb-0'>Option {rates.length + 1}</h2> */}
															<div className="side_triangle">
																<div className="d-flex justify-content-between align-items-center flex-column header_wrapper">
																	<div className="plan_choice">
																		<p className="choice_text text-uppercase">Best Choice</p>
																	</div>
																	<div className={"category-icon-wrapper"}>
																		<img className="category-icon mt-3 mb-4" key={index} src={`/images/${imageArray[imageIndex]}.png`} alt="" height={34} />
																	</div>
																	<h2 className='p-3 mb-0 category_title'>{row?.title}</h2>
																</div>
															</div>
															<div className="box-description">

																<div className='white-bg-text text-center plan_text fw-bold text-uppercase'>{row?.description} </div>
															</div>
															<div className="p-4 pt-0 pb-0 box-content">

																<div className="bg_grey row-col align-items-center d-flex justify-content-between">
																	<strong className='text-uppercase'>Porting fee</strong>
																	<span >{parseInt(row?.porting_price) > 0 ? '$' + row?.porting_price : 'None'}</span>
																</div>
																{
																	row?.minutes > 0 &&
																	<div className="box-description">
																		<strong className='text-uppercase d-flex justify-content-between bg_grey'>
																			<span>Minutes:</span>
																			<span>{row?.minutes}</span>
																		</strong>
																	</div>
																}

																<div className=" p-2 price-div d-flex justify-content-center align-items-center">
																	<strong className='plan-price'>${row?.price}  </strong>
																	<span className='plan-duration per_month_text ps-3 fw-bold'> per {row?.period !== 1 && row?.period} {row?.period_type}</span>

																</div>
																<div className="plan_text mb-3" style={{ fontSize: 14 }}>
																	Including all Taxes & Fees.
																</div>
															</div>
														</div>
													</label>
												</div>
											</>
										)
										rates = [...rates, items]

									}) :
									<div className="half-screen-loader">
										<MiniLoader />
									</div>
							}
							<div className="col">
								<MultiCarousel items={rates} />
							</div>
						</div>

						<FormFooter
							action={'save-plan'}
							activeStep={activeStep}
							setStep={setStep}
							centerProceed={true}
						/>
					</>
			}

		</>
	)
}

export default PlanOptions
