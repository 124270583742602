/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from "react";
import { NewNumberContext } from "contexts/FormsContext";
import ValidationError from "components/Order/ValidationError";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchNumbers } from "redux/actions/NewNumberAction";
import { toast } from "react-toastify";
import Select from 'react-select'
import { getDivisionsByCountry } from "redux/actions/StreetMapAction";

const customStyles = {
	option: (provided, state) => ({
		...provided,
		// borderBottom: '1px dotted pink',
		// color: state.isSelected ? 'red' : 'blue',
		// padding: 20,
	}),
	control: (provided) => ({
		...provided,
		// none of react-select's styles are passed to <Control />
		height: 56,
		width: "100%",
		background: "#fff",
		paddingLeft: 50,
		display: "flex",
		alignItems: "center",
		border: "1px solid #dbdbd9",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		return { ...provided, opacity, transition };
	}
}

const SelectNumber = () => {

	const history = useHistory();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext);
	const divisionList = useSelector(state => state.streetMap?.divisions?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.short_code.toLowerCase() } }));

	const previousStepUrl = '/new-number-order/country';
	const nextStepUrl = '/new-number-order/number-result';
	const { formState: { errors }, watch, setValue, handleSubmit, register } = useForm({
		defaultValues: {
			area_code: NewNumberData?.area_code || "",
			city: NewNumberData?.city || "",
			number_type: NewNumberData?.number_type || "",
			state: NewNumberData?.state || "",
			zip: NewNumberData?.zip || "",
			vanity: NewNumberData?.vanity || "",
		}
	});

	const numberType = watch('number_type');

	const submitHandler = (e) => {

		setLoading(true);
		const payload = {
			'country': NewNumberData.country?.code3,
			'areaCode': e.area_code,
			'quantity': 9,
			'enableTNDetail': false,
			'phoneType': e.number_type,
			'city': e.city,
			'state': e.state.code,
			'zip': e.zip
		}

		if (e.vanity != '') {
			if (numberType == 'local') {
				payload['localVanity'] = e.vanity;
			} else if (numberType == 'tollFree') {

				payload['tollFreeVanity'] = e.vanity;
			}
		}

		dispatch(searchNumbers(payload)).then(a => {
			if (a.bool) {
				console.log(a.result)
				if (a.result?.length > 0) {
					setNewNumberData({
						...NewNumberData,
						...e,
						'number_result': a.result[0]['TelephoneNumber'],
					});
					history.push(nextStepUrl);
				} else {
					toast.error("No numbers found", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" });
				}
			}
		}).catch(e => {
			console.log("e", e)
			toast.error(e.message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" });

		}).finally(e => setLoading(false))
	}

	useEffect(() => {

		if (NewNumberData?.country == null) {
			toast.error('Please go back and select country first.', { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" })
		}

		if (NewNumberData?.country) {
			dispatch(getDivisionsByCountry(NewNumberData.country.id));
		}
	}, [])

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading mb-5">Get a new phone number for calls, texts or faxes</h2>
					<div className="row mb-3">
						<div className="col">
							<label>Number Type</label>
							<div className="main-toggle">
								<input
									type="radio"
									id="local"
									value="local"
									{...register("number_type", { required: "Number type is required" })}
								/>
								<label htmlFor="local">Local</label>
								<input
									type="radio"
									id="tollFree"
									value="tollFree"
									{...register("number_type", { required: "Number type is required" })}
								/>
								<label htmlFor="tollFree">Toll Free</label>
								{/* <input type="radio" id="mobile" name="number_type" value="mobile" checked={form.number_type == "mobile"} onChange={handleChange} />
								<label htmlFor="mobile">Mobile</label> */}
							</div>
							{errors.number_type && <p className="text-danger">{errors.number_type.message}</p>}
						</div>
					</div>
					<div className="row mb-3">
						<div className="col">
							<label>State / Province</label>
							{/* <input type="text" {...register('state', {
								pattern: {
									value: /^[A-Za-z\s]+$/,
									message: "Only letters are allowed",
								},
							})} className="w-100 ps-3 area-code-number" placeholder="Eg., Florida, Ontario" /> */}
							<Select
								options={divisionList}
								name="state"
								isDisabled={divisionList.length == 0 || numberType == 'tollFree'}
								onChange={(e) => setValue('state', e)}
								className="generic-country-selects "
								styles={customStyles}
								// defaultValue={}
								value={watch('state')}
								menuPlacement="top"

							/>
							{errors.state && <p className="text-danger">{errors.state.message}</p>}
						</div>
						<div className="col">
							<label>City</label>
							<input type="text" {...register('city', {
								disabled: numberType == 'tollFree',
								pattern: {
									value: /^[A-Za-z\s]+$/,
									message: "Only letters are allowed",
								},
							})} className={`${numberType == 'tollFree' ? `bg-light` : `bg-white`} w-100 ps-3 area-code-number`} placeholder="E.g., Miami" />
							{errors.city && <p className="text-danger">{errors.city.message}</p>}
						</div>
					</div>
					<div className="row mb-3">
						<div className="col">
							<label>Zip code</label>
							<input type="text" {...register('zip', {
								disabled: numberType == 'tollFree',
								pattern: {
									value: /^\d+$/,
									message: "Only numbers are allowed",
								},
							})} className={`${numberType == 'tollFree' ? `bg-light` : `bg-white`} w-100 ps-3 area-code-number`} placeholder="Zip code" />
							{errors.zip && <p className="text-danger">{errors.zip.message}</p>}
						</div>
						<div className="col">
							<label>Area Code</label>
							<input type="text" {...register('area_code', {
								disabled: numberType == 'tollFree',
								pattern: {
									value: /^\d+$/,
									message: "Only numbers are allowed",
								},
								max: {
									value: 999,
									message: "Max 3 digits"
								}
							})} className={`${numberType == 'tollFree' ? `bg-light` : `bg-white`} w-100 ps-3 area-code-number`} placeholder="Type area code you are looking for" />
							{errors.area_code && <p className="text-danger">{errors.area_code.message}</p>}
						</div>
					</div>
					<div className="row mb-3">
						<div className="col">
							<label>Text in Number</label>
							<input type="text" {...register('vanity', {
								pattern: {
									value: /^[A-Za-z]+$/,
									message: "Only text is allowed",
								},
							})} className="w-100 ps-3 area-code-number" placeholder="Type text you want as number" />
							{errors.vanity && <p className="text-danger">{errors.vanity.message}</p>}
						</div>
					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">

								<button type='submit' className="proceed-btn mb-2" disabled={loading}>
									{
										loading ?
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											:
											"Proceed"
									}
								</button>
							</div>
						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => history.push(previousStepUrl)} className={"prev-step elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="button" data-title="Next" onClick={handleSubmit(submitHandler)} className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	);
}
export default SelectNumber
