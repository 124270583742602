import React, { useContext, useState } from "react";
import "../assets/css/credit-card.css";
import '@snowpak/react-credit-cards/lib/styles-compiled.css'
import "../assets/css/form-style.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { creditCardList } from "redux/actions/PaymentAction";
import MiniLoader from "components/Loader/MiniLoader";
import { NewNumberContext } from "contexts/FormsContext";
import ExistingPaymentProfile from "./sub-component/ExistingPaymentProfile";
import NewPaymentProfile from "./sub-component/NewPaymentProfile";
import { useHistory } from "react-router-dom";

const PaymentDetails = () => {
	const dispatch = useDispatch()
	const [isAlive, setIsAlive] = useState(true)
	const [toggleActive, setToggleActive] = useState("")

	const paymentProfileList = useSelector(state => state.payment.creditCardList);
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)

	const handleToggle = (e) => {
		// console.log("Here inside handleToggle", e)
		setToggleActive(e)
	}

	useEffect(() => {

		const getData = async () => {
			await dispatch(creditCardList(NewNumberData?.userData?.token)).then(row => {
				setIsAlive(false)
			});
		}
		if (isAlive) {
			getData()
		}

		setToggleActive(paymentProfileList?.result?.length > 0 ? "select-card" : "add-new-card")
	}, [paymentProfileList, setToggleActive])


	return (
		<>

			<div className="step">
				<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				{/* Existing Cards */}
				{
					isAlive ?
						<div className="m-5 p-5">
							<MiniLoader />
						</div>
						:
						toggleActive == "select-card" ?
							<ExistingPaymentProfile toggleStep={handleToggle} />
							:
						
					<NewPaymentProfile toggleStep={handleToggle} />
				}
			</div>
		</>
	)
}
export default PaymentDetails
