import useAuth from 'hooks/useAuth';
import React, { useState, createContext } from 'react'

const initialNewNumberState = {
	error: false,
	processing: false,
	ready: false,
	firstname: '',
	lastname: '',
	email: '',
	userData: null,
	country: null,
	number_result: null,
	selected_numbers: null,
	service_address: {},
	user_id: '',
	area_code: '',
	new_number: '',
	tollfree: false,
	voice: true,
	fax: false,
	sms: false,
	plan_id: '',
	plan_rate_id: '',
	plan_details: {},
	active_add_ons: [],
	
};


export const NewNumberContext = createContext({
	NewNumberData: initialNewNumberState,
	setNewNumberData: () => { },
})

export const NewNumberProvider = ({ children }) => {
	const { user } = useAuth();
	const [NewNumberData, setNewNumberData] = useState({
		...initialNewNumberState,
		firstname: user?.first_name || '',
		lastname: user?.last_name || '',
		email: user?.email || '',
		user_id: user?.id || '',
		userData: user,
	})

	return (
		<NewNumberContext.Provider value={{ NewNumberData, setNewNumberData }}>
			{children}
		</NewNumberContext.Provider>
	);
}


export const ExistingNumberContext = createContext()

export const ExistingNumberProvider = props => {

	const { user } = useAuth();

	const [ExistingNumberData, setExistingNumberData] = useState({
		firstname: user?.first_name,
		lastname: user?.last_name,
		email: user?.email,
		user_id: user?.id ?? "",
		userData: user,
		order_id: '',
		order_did: [],
		error: [],
		processing: false,
		ready: false,
		first_number: '',
		plan: '',
		plan_rate_id: '',
		verification_pref: "",
		pinCall: '',
		pinText: '',
		schedule_to_port: '',
		is_this_a_mobile_number: '',
		keep_the_number_as_a_mobile_phone_number: 'NO',
		use_number_only_to_send_receive_faxes: '',
		carrier: '',
		account_number_for_this_account_with_phone_business: '',
		is_this_the_main_number_on_the_business_account: '',
		please_specify_the_main_number_on_the_business_account: '',
		does_this_account_belong_to_the_business: '',
		are_there_any_more_number_that_you_want_to_port: '',
		how_many_number_you_have_on_the_account: [],
		provide_the_port_out_pin_number: '',
		upload_copy_of_latest_phone_bill: '',
		when_do_you_want_port_the_number: '',
		your_business_name_on_the_account: '',
		service_address_where_the_phone_physically_installed: '',
		planChange: false,
		plan_voice_email: "",
		plan_text_email: "",
		plan_forward_number: "",
		plan_forward_number_carrier: "",
		plan_forward_number_country_id: "",
		signature: '',
		signature_text: '',
		business_name: '',
		authorized_first_name: "",
		authorized_last_name: "",
		signature_font: '',
		btn_number: '',
		name_on_card: "",
		card_number: "",
		expiry: "",
		expiryyear: "",
		cvc: "",
		terms_condition: "",
		focus: '',
		order_summery: '',
		success_message: '',
		is_this_a_business_account: '',
		logged_in_copy_toggle: false,
		port_number_is_agree: '',
		active_add_ons: [],
		active_add_ons_for_numbers: [],
		type_or_sign: false,
		is_business_account: false,
		plan_details: {}
	})

	return (
		<ExistingNumberContext.Provider value={{ ExistingNumberData, setExistingNumberData }}>
			{props.children}
		</ExistingNumberContext.Provider>
	);
}

export const SignupContext = createContext("")

export const SignupProvider = props => {

	const [SignupData, setSignupData] = useState({
		first_phone: '',
		activeSocialFormat: "",
		email: '',
		goals: [
			{
				title: 'I am traveling and want to keep my phone number.',
				answer: false
			},
			{
				title: 'I am moving to an area where my current provider cannot provide service.',
				answer: false
			},
			{
				title: 'My elderly parents are moving to a home and want to keep their phone number.',
				answer: false
			}
		]
	})
	return (
		<SignupContext.Provider value={{
			signupData: SignupData,
			setSignupData: setSignupData
		}}>
			{props.children}
		</SignupContext.Provider>
	);
}
export const CoverageContext = createContext({
	coverage: {},
	setCoverage: () => { }
})

export const CoverageProvider = props => {

	const { user } = useAuth();

	const [CoverageData, setCoverageData] = useState({
		error: "",
		processing: false,
		ready: false,
		first_phone: '',
		accountFor: '',
		addMorePPL: 'NO',
		plan: 'VOICEMAIL',
		activeSocialFormat: "",
		firstname: user?.first_name || '',
		lastname: user?.last_name || '',
		email: user?.email || '',
		checkedBy: user?.id || '',
		port: [{ number: "", type: "", countryCode: "" }],
		portabilityResponse: "",
		userdata: "",
		goals: [
			{
				title: 'I am traveling and want to keep my phone number.',
				answer: false
			},
			{
				title: 'I am moving to an area where my current provider cannot provide service.',
				answer: false
			},
			{
				title: 'My elderly parents are moving to a home and want to keep their phone number.',
				answer: false
			}
		]

	})

	return (
		<CoverageContext.Provider value={{
			coverage: CoverageData,
			setCoverage: setCoverageData
		}} >
			{props.children}
		</CoverageContext.Provider >
	);
}
