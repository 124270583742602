import { NewNumberContext } from 'contexts/FormsContext';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PlanCategory from './sub-component/PlanCategory';
import { parsePhoneNumber } from 'libphonenumber-js';
import { formatPhone } from 'general/FormatNumber';
import PlanRates from './sub-component/PlanRates';
import { useForm } from 'react-hook-form';


const Plans = () => {
	const phoneNumbers = ['1234567890', '1987654321', '1122334455', '1234567890', '1987654321', '1122334455'];
	const [activeTab, setActiveTab] = useState(phoneNumbers[0]); // Default to the first phone number
	const { formState: { errors }, handleSubmit } = useForm();
	const history = useHistory();
	const previousStepUrl = '/new-number-order/number-result';
	const nextStepUrl = '/new-number-order/name-on-account';
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext);
	const [loading, setLoading] = useState(false);

	const handleTabClick = (phoneNumber) => {
		setActiveTab(phoneNumber);
	};

	const submitHandler = (data) => {
		setLoading(true);
		// setNewNumberData({ ...NewNumberData, selected_numbers: data.selected_numbers });
		history.push(nextStepUrl);
		setLoading(false);
	};

	return (
		<div className="step">
			<form onSubmit={handleSubmit(submitHandler)}>
				<h5 className="mb-2 sm-heading">ORDER</h5>
				<h2 className="lg-heading mb-5">Choose plan for selected numbers</h2>
				<div className="row mb-lg-4">
					{/* Phone Number Tabs */}
					<div className="col-md-3">
						<div
							className="nav flex-column nav-pills"
							id="v-pills-tab"
							role="tablist"
							aria-orientation="vertical"
						>
							{phoneNumbers.map((number) => (
								<a
									key={number}
									className={`nav-link ${activeTab === number ? 'active' : ''}`}
									onClick={() => handleTabClick(number)}
									role="tab"
									href="#!"
								>
									{formatPhone(number)}
								</a>
							))}
						</div>
					</div>

					{/* Plan Content */}
					<div className="col-md-9">
						<div className="tab-content">
							{phoneNumbers.map((number) => (
								<div
									key={number}
									className={`tab-pane fade ${activeTab === number ? 'show active' : ''}`}
								>
									<h4 className='text-center'>Plans for <b>{formatPhone(number)}</b></h4>
									<PlanCategory />
									<PlanRates />
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="row">
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">

								<button type='submit' className="proceed-btn mb-2" disabled={loading}>
									{
										loading ?
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											:
											"Proceed"
									}
								</button>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => history.push(previousStepUrl)} className={"prev-step elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="button" data-title="Next" onClick={handleSubmit(submitHandler)} className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</div>
			</form>
		</div>
	);
};

export default Plans;
