import ValidationError from 'components/Order/ValidationError';
import { NewNumberContext } from 'contexts/FormsContext';
import useAuth from 'hooks/useAuth';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

const NameOnAccount = () => {
    const { isAuthenticated } = useAuth();
    const { NewNumberData, setNewNumberData } = useContext(NewNumberContext);
    const history = useHistory();

    const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            is_business_account: NewNumberData?.is_business_account || false,
            business_name: NewNumberData?.business_name || '',
            authorized_first_name: NewNumberData?.authorized_first_name || '',
            authorized_last_name: NewNumberData?.authorized_last_name || '',
            copy_logged_in: NewNumberData?.copy_logged_in,
        },
    });

    const previousStepUrl = '/new-number-order/plans';
    const nextStepUrl = '/new-number-order/service-address';
    const [loading, setLoading] = useState(false);

    const isBusinessAccount = watch('is_business_account');
    const copyLoggedIn = watch('copy_logged_in');

    // Function to auto-fill user data
    const copyAuthUser = (checked) => {
        if (checked) {
            if (isAuthenticated) {
                setValue('authorized_first_name', NewNumberData?.userData?.first_name || '');
                setValue('authorized_last_name', NewNumberData?.userData?.last_name || '');
            }
        } else {
            setValue('authorized_first_name', '');
            setValue('authorized_last_name', '');
        }
    };

    const onSubmit = (data) => {
        setLoading(true);
        setNewNumberData({...NewNumberData, ...data});
        history.push(nextStepUrl); // Replace with actual URL
        setLoading(true);
    };

    return (
        <>
            <ValidationError errors={errors} />

            <form onSubmit={handleSubmit(onSubmit)} className="step">
                <h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
                <h2 className="lg-heading">Enter name on the account:</h2>

                {/* Business Account Checkbox */}
                <div className="row">
                    <div className="col">
                        <div className="item my-2">
                            <input
                                type="checkbox"
                                id="account-on-company-name"
                                className="custom-cb"
                                {...register('is_business_account')}
                            />
                            <label htmlFor="account-on-company-name">Is this a business account</label>
                        </div>
                    </div>
                </div>

                {/* Copy Data from Logged-in User */}
                {isAuthenticated && (
                    <div className="row">
                        <div className="col">
                            <div className="item my-2">
                                <input
                                    type="checkbox"
                                    id="copydata-from-logged-in-user"
                                    className="custom-cb"
                                    {...register('copy_logged_in', {
                                        onChange: (e) => {
                                            copyAuthUser(e.target.checked);
                                        },
                                    })}
                                />
                                <label htmlFor="copydata-from-logged-in-user">Copy data from the logged-in user</label>
                            </div>
                        </div>
                    </div>
                )}

                {/* Business Name Input */}
                {isBusinessAccount && (
                    <div className="row">
                        <div className="col signupbutton_content">
                            <div className="email field">
                                <img className="icon user" src="/images/forms/user.png" alt="" />
                                <label htmlFor="business_name">Business Name</label>
                                <input
                                    id="business_name"
                                    type="text"
                                    placeholder="Business Name"
                                    {...register('business_name', {
                                        required: isBusinessAccount ? 'Business name is required' : false,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Authorized First and Last Name */}
                <div className="row mt-3">
                    <div className="col-lg-6 col-12 signupbutton_content">
                        <div className="email field">
                            <img className="icon user" src="/images/forms/user.png" alt="" />
                            <label htmlFor="authorized_first_name">Authorized First Name</label>
                            <input
                                id="authorized_first_name"
                                type="text"
                                placeholder="First Name"
                                readOnly={copyLoggedIn}
                                {...register('authorized_first_name', {
                                    required: 'First name is required',
                                })}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 signupbutton_content">
                        <div className="email field">
                            <img className="icon user" src="/images/forms/user.png" alt="" />
                            <label htmlFor="authorized_last_name">Authorized Last Name</label>
                            <input
                                id="authorized_last_name"
                                type="text"
                                placeholder="Last Name"
                                readOnly={copyLoggedIn}
                                {...register('authorized_last_name', {
                                    required: 'Last name is required',
                                })}
                            />
                        </div>
                    </div>
                </div>

                {/* Footer Buttons */}
                <div className="footer d-flex flex-column align-items-center w-100 mt-4">
                    <button
                        type="submit"
                        className="proceed-btn mb-2"
                        disabled={loading}
                    >
                        {loading ? (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            "Proceed"
                        )}
                    </button>

                    <div className="navigation-buttons">
                        <button
                            type="button"
                            onClick={() => history.push(previousStepUrl)}
                            className="prev-step elaboration"
                            data-title="Previous"
                        >
                            <img src="/images/forms/icon-prev.png" alt="Previous" />
                        </button>

                        <button
                            type="submit"
                            className="next-step elaboration"
                            data-title="Next"
                            disabled={loading}
                        >
                            <img src="/images/forms/icon-next.png" alt="Next" />
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default NameOnAccount;
