import React, { useState } from "react";
import MiniLoader from "components/Loader/MiniLoader";
import "../../assets/css/new-number-plan.scoped.css"; // Add your CSS file for styles

const PlanCategory = () => {
  const categoryList = [
    {
      id: 1,
      name: "Deep freeze",
      status: true,
      original_price: "36",
      period_as_month: 12,
      least_price: "3",
    },
    {
      id: 2,
      name: "Voicemail",
      status: true,
      original_price: "15",
      period_as_month: 3,
      least_price: "5",
    },
    {
      id: 3,
      name: "Call Forwarding",
      status: true,
      original_price: "72",
      period_as_month: 12,
      least_price: "6",
    },
    {
      id: 4,
      name: "Wifi Calling",
      status: true,
      original_price: "143",
      period_as_month: 12,
      least_price: "12"
    }
  ];


  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSelectCategory = (id) => {
    setSelectedCategory(id);
  };

  return (
    <div className="radio-inputs">
      {categoryList.length > 0 ? (
        categoryList.map((category) => (
          <label key={category.id}>
            <input
              className="radio-input"
              type="radio"
              name="category"
              value={category.id}
              checked={selectedCategory === category.id}
              onChange={() => handleSelectCategory(category.id)}
            />
            <span className="radio-tile">
              <span className="radio-label">{category.name}</span>
              <span className="radio-details">
                ${category.least_price}/mo
              </span>
            </span>
          </label>
        ))
      ) : (
        <div className="half-screen-loader">
          <MiniLoader />
        </div>
      )}
    </div>
  );
};

export default PlanCategory;
