import React, { useEffect, useState, useContext } from "react";
import { CoverageContext } from "contexts/FormsContext";
import PhoneInput from "react-phone-input-2";
import { parsePhoneNumber } from "libphonenumber-js";
import RoundedButton from "components/Buttons/RoundedButton";
import ValidationError from "components/Order/ValidationError";
import { useForm } from "react-hook-form";
import Radio from "components/Radio/Radio";
import { useDispatch } from "react-redux";
import { coverageCreate, coverageUpdate } from "redux/actions/CoverageActions";
import { unformatedPhone } from "general/FormatNumber";
import { toast } from "react-toastify";

const AddingNumbers = ({ setStep, setProcessing, activeStep, heading, placeholder, prevStep }) => {

	const dispatch = useDispatch();
	const { coverage, setCoverage } = useContext(CoverageContext)
	const { formState: { errors }, setError, clearErrors, handleSubmit, setValue, watch } = useForm({
		defaultValues: {
			number: "",
			country_code: "",
			number_type: ""
		}
	})

	const options = [
		{
			label: "Landline",
			value: "landline"
		},
		{
			label: "Mobile",
			value: "mobile"
		},
		{
			label: "Fax",
			value: "fax"
		},
		{
			label: "Toll-Free",
			value: "toll_free"
		}
	]

	const [toNumberOptions, setToNumberOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const [phoneNumber, setPhoneNumber] = useState({
		number: "",
		countryCode: "",
		type: ""
	})
	const [selectedOption, setSelectedOption] = useState("");

	const selectNumberType = watch('number_type')

	const handlePhoneNumberChange = (phone, details) => {

		clearErrors('phoneNumber')

		let phoneNumber = null;
		if (phone.length >= 11) {
			phoneNumber = parsePhoneNumber(phone, details?.countryCode?.toUpperCase());

			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setError('phoneNumber', { message: "Number is invalid" })
			}
			setValue('number', phone)
			setValue('country_code', details?.countryCode?.toUpperCase())
			// setValue('number_type', phoneNumber.getType())
		}

		setPhoneNumber({
			number: phone,
			countryCode: details.countryCode.toUpperCase(),
			type: phoneNumber?.getType()
		})
	}

	const populateSelectToOption = (value) => {

		setSelectedOption(value)

		switch (value) {
			case "landline":
			case "mobile":

				setToNumberOptions([
					{
						label: "Landline",
						value: "landline"
					},
					{
						label: "Mobile",
						value: "mobile"
					},
					{
						label: "Fax",
						value: "fax"
					}
				]);
				break;
			case "fax":
			case "toll_free":
				setToNumberOptions([
					{
						label: "Toll-Free",
						value: "toll_free"
					},
					{
						label: "Fax",
						value: "fax"
					}
				])
				break;
			default:
				break;
		}
	}

	const onSubmit = async (data) => {

		try {
			// Clear previous errors
			clearErrors();
			setLoading(true)
			const portNum = parsePhoneNumber(data.number, data.country_code);

			if (!selectNumberType) {
				setError('number_type', {
					type: "required",
					message: "Please select a valid number type."
				});
				setLoading(false)
				return;
			}

			if (coverage.phone) {
				parsePhoneNumber(coverage.phone, data.country_code);
			}

			const detectedType = portNum.getType(); // e.g., "FIXED_LINE", "MOBILE", "TOLL_FREE", etc.

			const typeMapping = {
				landline: ["FIXED_LINE", "FIXED_LINE_OR_MOBILE"],
				mobile: ["MOBILE", "FIXED_LINE_OR_MOBILE"],
				fax: ["FIXED_LINE", "FIXED_LINE_OR_MOBILE"],
				toll_free: ["TOLL_FREE"]
			};

			// Check if detected type matches selected type
			if (!typeMapping[selectNumberType]?.includes(detectedType)) {
				setError('number_type', {
					type: "manual",
					message: `Selected number type (${selectNumberType}) does not match the actual type.`
				});
				setLoading(false)
				return;
			}

			const payload = {
				'fname': coverage.firstname,
				'lname': coverage.lastname,
				'email': coverage.email,
				'country': data.country_code,
				"checkedBy": coverage.checkedBy || "",
				"phone_type": data.number_type,
				"port": unformatedPhone(data.number)
			}

			if (coverage.phone) {
				payload['phone'] = unformatedPhone(coverage.phone)
			}

			await dispatch(coverageCreate(payload)).then(async response => {

				if (!response.bool) {
					setLoading(false)
					toast.error(response.message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" });
					return false;
				}

				payload['id'] = response.result.id;
				payload['checkPortability'] = true;

				await dispatch(coverageUpdate(payload)).then(response => {
					setLoading(false)
					const numberResponse = [];
					response.result.forEach(element => {
						const rawResponse = []
						let portable = false
						// get raw response for more accuracy
						const payload = JSON.parse(element.response)

						Object.values(payload).forEach((value, key) => {
							if (value.result?.portable === true) {
								portable = true
							}
							rawResponse['portable'] = portable
							// rawResponse['response'] = value.result?.portable
						})

						rawResponse['phoneNumber'] = element.phone_no
						numberResponse.push(rawResponse)
					});
					setCoverage({ ...coverage, 'id': response.result.id, 'portabilityResponse': numberResponse })
					setStep(activeStep + 1)
				});

			}).finally(f => setLoading(false));

		} catch (error) {
			setLoading(false)
			console.log(error)
			toast.error(error.message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" });
		}
	}

	useEffect(() => {
		if (!selectedOption) {
			populateSelectToOption("landline")
		}
		if (selectNumberType) {
			clearErrors("number_type")
		}
	}, [selectNumberType])

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				<h2 className="lg-heading mb-5">Use the form below to see if your number is eligible for Park My Phone phone number storage.</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row position-relative">
						<div className="col mt-2">
							<label className="form-label select-label mb-0">Enter Number:</label>
							<PhoneInput
								onlyCountries={['us', 'ca']}
								disableDropdown={true}
								countryCodeEditable={false}
								inputClass="default"
								value={phoneNumber.number}
								country={'us'}
								onChange={(phone, details) => {
									handlePhoneNumberChange(phone, details)
								}}
							/>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col">
							<div className="col d-flex flex-column mb-2">
								<label className="form-label select-label mb-0">Current Number Type</label>
								<Radio name="current_number_state"
									options={options}
									selectedValue={selectedOption}
									onChange={(value) => { populateSelectToOption(value) }}
								/>
							</div>
						</div>
						<div className="col">
							<div className="col">
								<label className="form-label select-label mb-0">Port Number As:</label>
								<div className="w-100">
									<Radio name="to_number_state"
										options={toNumberOptions}
										selectedValue={selectNumberType}
										onChange={(value) => setValue('number_type', value)}
									/>
								</div>
							</div>
						</div>

					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} loadingText={'Loading'} showLoader={true}>
									Proceed
								</RoundedButton>
								{/* <div className="pe">
								Press Enter
								<img src="/images/forms/icon-enter.png" alt="" />
							</div> */}
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default AddingNumbers
