import { ExistingNumberContext, NewNumberContext } from 'contexts/FormsContext';
import React, { useState } from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'

const PlanRates = ({ show = false, data, setShow = () => { } }) => {

	const [showModal, setShowModal] = useState(show);
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)

	const setPlanRateId = (e) => {

		setNewNumberData({
			...NewNumberData,
			'plan_id': e.plan_id,
			'planChange': true,
			'plan_rate_id': e.id
		})


	}

	useEffect(() => {
		return () => {
			setShowModal(false)
		}
	}, [showModal, data])

	return (
		<>
		</>
	)
}

export default PlanRates
