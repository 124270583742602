import React from "react";

const StepCounter = ({ stepTitle }) => {
    const maxSteps = 5;
    let stepsCounterMarkup = [];

    for (let index = 1; index < maxSteps; index++) {
        stepsCounterMarkup.push(
            <li
                key={index}
                data-title={stepTitle[index] + " (" + index + ")"}
                // className={(index === activeStep ? "active " : index < activeStep ? "accessable active " : maxStepReached >= index ? "accessable " : "") + "navigationItem elaboration bottom"}
                className={"accessable navigationItem elaboration bottom"}
            >
            </li>
        );
    }
    return stepsCounterMarkup;
};

export default StepCounter;