import ValidationError from 'components/Order/ValidationError';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import '../assets/css/country-select.scoped.css';
import ReactCountryFlag from 'react-country-flag';
import { useHistory } from 'react-router-dom';
import { NewNumberContext } from 'contexts/FormsContext';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'redux/actions/NewNumberAction';

const CountryCard = ({ country, onSelect, selectedId }) => {
    return (
        <div
            className={`col-3 m-2 text-center rounded radio bg-white ${selectedId === country.id ? "selected" : ""}`}
            onClick={() => onSelect(country)}
            style={{ cursor: "pointer" }}
        >
            <div className="country-card text-center">
                <ReactCountryFlag
                    countryCode={country.code}
                    svg
                    style={{ width: "50px", height: "35px" }}
                    title={country.code}
                />
                <div className="country-name mt-2">{country.name}</div>
            </div>
        </div>
    );
};

const SelectCountry = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const previousStepUrl = '/order';
    const nextStepUrl = '/new-number-order/select-number';
    const countryList = useSelector(state => state.newNumber.countryList)
    const { formState: { errors }, handleSubmit, register, setValue, watch } = useForm();
    const { NewNumberData, setNewNumberData } = useContext(NewNumberContext);
    const [loading, setLoading] = useState(true);

    const selectedCountryId = watch("country", "");

    const submitHandler = (data) => {
        setLoading(true);
        setNewNumberData({ ...NewNumberData, ...data });
        history.push(nextStepUrl);
        setLoading(false);
    };

    const handleCountrySelect = (id) => {
        setValue("country", id, { shouldValidate: true }); // Update form state
    };

    useEffect(() => {

        setValue("country", NewNumberData?.country)
        dispatch(getCountries()).then(a => setLoading(false))
    }, [])

    return (
        <>
            <ValidationError errors={errors} />
            <div className="step">
                <form onSubmit={handleSubmit(submitHandler)}>
                    <h5 className="mb-2 sm-heading">Select Country</h5>
                    <h2 className="lg-heading mb-5">Let's start by telling us your country</h2>
                    <div className="radio-group row justify-content-center align-items-center px-3 text-center a">
                        {countryList.map((country) => (
                            <CountryCard
                                key={country.id}
                                country={country}
                                onSelect={handleCountrySelect}
                                selectedId={selectedCountryId?.id}
                            />
                        ))}
                    </div>

                    <input
                        type="hidden"
                        {...register("country", { required: "Country selection is required" })}
                    />

                    {/* From Footer */}
                    <>
                        <div className={"footer"} >
                            {/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
                            <div className="d-flex flex-column align-items-center w-100">

                                <button type='submit' className="proceed-btn mb-2" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            :
                                            "Proceed"
                                    }
                                </button>
                            </div>

                        </div>
                        {/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
                        <button type="button" data-title="Previous" onClick={() => history.push(previousStepUrl)} className={"prev-step elaboration right"}>
                            <img src="/images/forms/icon-prev.png" alt="" />
                        </button>
                        {/* <a className="next-step" onclick="form.steps('next')"> */}
                        <button type="button" data-title="Next" onClick={handleSubmit(submitHandler)} className="next-step elaboration right">
                            <img src="/images/forms/icon-next.png" alt="" />
                        </button>
                    </>
                    {/* From Footer end */}
                </form>
            </div>
        </>
    );
};

export default SelectCountry;
