
import { CoverageContext } from "contexts/FormsContext"
import { useContext } from "react"
import FormFooter from "./FormFooter"
import { formatPhone } from "general/FormatNumber";

const CoverageFound = ({ setStep, activeStep }) => {

	const { coverage } = useContext(CoverageContext)
	return (
		<>
			{
				coverage?.portabilityResponse ?


					<>
						<div className="step">
							<img className="center-check" src="/images/forms/heart.png" width="75" alt="Success" />
							<h2 className="lg-heading text-center mt-4">Your Portability check was successful!</h2>

							<h3 className="text-center fs-5">
								We found out your number:
							</h3>
							<h3 className="text-center fs-5">
								{
									coverage?.portabilityResponse.map((number, value) =>
										<div key={value}>

											<b>{formatPhone(number.phoneNumber)}</b> is {number.portable ? <b className="text-success text-uppercase">Portable</b> : <b className="text-danger text-uppercase">Not Portable</b>} <br />
										</div>
									)
								}
								{/* Yes, your number is portable */}
							</h3>
							{/* <h3 className="text-center fs-5">
										Yes, your number is portable and it can be ported as:
									</h3> */}
							{/* <h3 className="text-center fs-5 mb-4 d-flex justify-content-around mx-auto">
								<div className="item-coverage-found">
									<img alt="" src="/images/forms/tickmark.png" />Landline
								</div>
								<div className="item-coverage-found">
									<img alt="" src="/images/forms/tickmark.png" />Fax
								</div>
								<div className="item-coverage-found">
									<img alt="" src="/images/forms/tickmark.png" />Mobile
								</div>
								<div className="item-coverage-found">
									<img alt="" src="/images/forms/tickmark.png" />Toll Free
								</div>
							</h3> */}
							<h5 className="text-center color-strange fw-normal">Now Let's Finish
								setting up your account to place your order with Park My Phone.
								Please note that you can always
								<span className="fst-italic"> <b>select your desired option when you are ready to place the order. </b></span>
								If you are an existing customer
								then please click the Login button otherwise simply click Continue
							</h5>
							<FormFooter activeStep={activeStep} setStep={setStep} centerProceed={true} login={true} />
						</div >
					</>



					:
					<>
						<div className="step">
							{/* <img class="center-check" src="/images/forms/heart.png" width="75" alt="Success" /> */}
							<div className="d-flex justify-content-center">
								<svg className="crossmark addClass w-15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" >
									<circle className="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
									<path className="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
									<path className="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
								</svg>
							</div>
							<h2 className="lg-heading text-center mt-4">No Response from coverage check!</h2>
							<h3 className="text-center fs-5">
								Go back to previous step and try some numbers
							</h3>
							<FormFooter action={"signup-or-dashboard"} activeStep={activeStep} setStep={setStep} centerProceed={true} login={true} />
						</div >
					</>
			}

		</>
	)
}

export default CoverageFound
