import React, { useContext } from "react";

const NumberSidebar = () => {
    return (
        <>
            <div className="sidebarData">
                <p>Preference: <b>New Number</b></p>
            </div>
        </>
    )
}

export default NumberSidebar;