import React from 'react';

const Summary = () => {
    return (

        <div className="step px-0">
            <h5 className="mb-2 sm-heading text-center">CONGRATULATIONS ON COMPLETING YOUR ORDER</h5>
            <h2 className="lg-heading text-center text-uppercase mb-3">Order Summary</h2>
            <div className="order_summary">
                <div className="row px-4">
                    <div className="col-md-4 col-12 pt-3">
                        <div className="order_summary_left">
                            <div className="px-4">
                                <div className="row">
                                    <div className="col-10">
                                        <h4><b>Account Details:</b></h4>
                                        <p className="fw-normal fs-5 m-0"><b>Name:</b> John Doe</p>
                                        <p className="fw-normal fs-5 mb-0"><b>Address:</b> 123 Dummy Street</p>
                                        <p className="fw-normal fs-5 mb-3">Account: 123456789</p>
                                    </div>
                                    <div className="col-2">
                                        <div className="changeLink text-center elaboration left" data-title="Change account Details"><i className="fa fa-pencil"></i></div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-10">
                                        <h4><b>Your Plan Details:</b></h4>
                                    </div>
                                    <div className="col-2">
                                        <div className="changeLink text-center elaboration left" data-title="Change Plan Details"><i className="fa fa-pencil"></i></div>
                                    </div>
                                </div>
                                <h4 className="orange">Basic Plan</h4>
                                <p className="fw-normal fs-6 mb-0">Duration: <span className="blue">12 Months</span></p>
                                <p className="fw-normal fs-6 mb-0">AddOns: <span className="blue">AddOn1, AddOn2</span></p>
                            </div>

                            <div className="p-4">
                                <hr />
                                <div className="row">
                                    <div className="col-10">
                                        <h4><b>Payment Details:</b></h4>
                                    </div>
                                    <div className="col-2">
                                        <div className="changeLink text-center elaboration left" data-title="Change Payment Details"><i className="fa fa-pencil"></i></div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="fw-normal fs-5 mb-0">Payment Method: Credit Card</p>
                                    <div className="d-flex my-2 align-items-center">
                                        <img src="/images/credit-cards/credit-card.png" className="img-fluid col-2" alt="" />&nbsp;
                                        <h4><span className="orange">**** **** **** 1234</span></h4>
                                    </div>
                                    <p className="fw-normal fs-6 mb-0">Name: <span className="blue">John Doe</span></p>
                                    <p className="fw-normal fs-6 mb-0">Expiry: <span className="blue">12/25</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8 col-12 aligns-items-center justify-content-center pt-3 pe-4">
                        <h4 className="text-center"><b>Invoice</b></h4>
                        <div className="order_summary_right">
                            <div className="phone_number_summary">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Items</th>
                                            <th className="text-left">Duration</th>
                                            <th className="text-end">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="summary_number_details ">
                                                <div className="country-icon"><img alt="" src="/images/forms/usaicon.png" className="img-fluid" /> </div>
                                                <div className="ms-3">
                                                    <div className="country-number">+1 123-456-7890</div>
                                                    <div className="micro package_name"><b>Plan</b>- Basic Plan</div>
                                                    <div className="micro package_name"><b>Add ons</b>- AddOn1, AddOn2</div>
                                                    <div className="micro package_name"><b>Additional Charges</b> - Porting, Shipping</div>
                                                </div>
                                            </td>
                                            <td className="text-left">12 Months</td>
                                            <td className="text-end">$99.99</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr className="border">
                                            <td colSpan="2">Subtotal</td>
                                            <td className="text-end">$99.99</td>
                                        </tr>
                                        <tr className="border">
                                            <td colSpan="2" className="fw-bold">Total</td>
                                            <td className="fw-bold orange text-end">$99.99</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <button type="button" className="order-summary-dashboard-btn btn-lg my-3 mx-auto">
                                        Process My Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Summary;
