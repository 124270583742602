/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";
import { useContext } from "react";
import { toast } from 'react-toastify'
import { useDispatch } from "react-redux";
import { bulkPlanForDId, deleteDidService, orderCreate, orderDidAddOn, orderDIDCreate, orderDidUpdate, orderSignature, orderUpdate, saveForwardNumber, savePaymentProfile, saveVoiceMail, updateOrderStepTracking, verifyDid } from "redux/actions/OrderAction";
import { unformatedPhone } from "general/FormatNumber";
import moment from "moment";
import RoundedButton from "components/Buttons/RoundedButton";
import { checkValidateViaAPI } from "redux/actions/UserAction";


const validateEmail = (email) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

const FormFooter = ({ setStep, activeStep, centerProceed, final, login, dependency = true, cf, action, payloadForUpdate, disabledViaForm = false, loadingText = "Loading" }) => {

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false);
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const validateMaster = (data, message = "", delay = 0, method = "") => {
		let temp = []
		Object.keys(data).map((key, index) => {
			if (data[key] == undefined || ExistingNumberData?.first_number?.phoneNumber == "+1 () -" || data[key] == "") {
				temp = temp.concat(key);
				setExistingNumberData({ ...ExistingNumberData, processing: false });
			}
			//console.log(key)
			if (key == "card_number" && data[key].length < 11) {
				temp = temp.concat(key);
				setExistingNumberData({ ...ExistingNumberData, processing: false });
				//console.log('error define')
			}
			if (key == "phone_number" && unformatedPhone(data[key]).length != 11) {
				temp = temp.concat(key);
				setExistingNumberData({ ...ExistingNumberData, processing: false });
				//console.log('error define')
			}
		})
		if (temp.length > 0) {
			setExistingNumberData({ ...ExistingNumberData, processing: false, error: temp });
			return false;
		} else {
			setExistingNumberData({ ...ExistingNumberData, error: [] });
			return true;
		}
	}
	const validateGeneral = () => {
		setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
	}
	const updateNewOrder = async () => {
		let check = Object.keys(payloadForUpdate)[0];
		//console.log(payloadForUpdate[check]);
		let validated = false;
		validated = validateMaster({
			"question": payloadForUpdate[check]
		})

		if (validated) {
			// Key of object
			let firstKey = Object.keys(payloadForUpdate)[0];
			// console.log(firstKey)
			// return;
			if (firstKey === "btn") {
				if (payloadForUpdate[firstKey] === "NO") {
					await dispatch(updateOrderStepTracking({
						'order_id': ExistingNumberData.order_id,
						'step_id': activeStep,
						'state_body': ExistingNumberData,
					}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
					setStep(activeStep + 1)
					return
				} else {
					setLoading(true)
					const payload = {
						'order_id': ExistingNumberData?.order_id,
						"btn": unformatedPhone(ExistingNumberData?.first_number?.phoneNumber)
					}
					await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async (response) => {

						await dispatch(updateOrderStepTracking({
							'order_id': ExistingNumberData?.order_id,
							'step_id': activeStep,
							'state_body': ExistingNumberData,
						}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))

						setLoading(false)
						showToast()
						setStep(activeStep + 1)
						setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
					}).catch(exp => {
						setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
						console.log(exp)
						setLoading(false)
					})
					return
				}
			}
			if (firstKey === "port_date") {
				setLoading(true)

				const payload = {
					'order_id': ExistingNumberData?.order_id,
					"port_date": moment(payloadForUpdate[firstKey].time).format('Y-M-D')
				}
				await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
					await dispatch(updateOrderStepTracking({
						'order_id': ExistingNumberData.order_id,
						'step_id': activeStep,
						'state_body': ExistingNumberData,
					}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
					setLoading(false)
					showToast()
					setStep(activeStep + 1)
					setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
				}).catch(exp => {
					setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
					console.log(exp)
					setLoading(false)
				})
				return
			}
			if (firstKey === "is_fax") {
				// if (payloadForUpdate[firstKey] === "YES" && ExistingNumberData.keep_the_number_as_a_mobile_phone_number === "YES" && ExistingNumberData.is_this_a_mobile_number === "YES") {
				if (payloadForUpdate[firstKey] === "YES" && ExistingNumberData.is_this_a_mobile_number === "YES") {
					setExistingNumberData({ ...ExistingNumberData, error: ["fax"], processing: false, ready: false })
					return
				} else {
					setLoading(true)
					const payload = {
						'order_id': ExistingNumberData?.order_id,
						'number_id': ExistingNumberData?.order_did[0]?.id,
						[firstKey]: payloadForUpdate[firstKey] === "YES" ? true : false
					}

					await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
						await dispatch(updateOrderStepTracking({
							'order_id': ExistingNumberData.order_id,
							'step_id': activeStep,
							'state_body': ExistingNumberData,
						}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
						setLoading(false)
						showToast()
						setStep(activeStep + 1)
						setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
					}).catch(exp => {
						setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
						console.log(exp)
						setLoading(false)
					})
					return
				}
			}
			setLoading(true)
			const payload = {
				'order_id': ExistingNumberData?.order_id,
				'number_id': ExistingNumberData?.order_did[0]?.id,
				[firstKey]: payloadForUpdate[firstKey] === "YES" ? true : false
			}

			await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
				await dispatch(updateOrderStepTracking({
					'order_id': ExistingNumberData.order_id,
					'step_id': activeStep,
					'state_body': ExistingNumberData,
				}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
				setLoading(false)
				showToast()
				setStep(activeStep + 1)
				setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
			}).catch(exp => {
				setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
				console.log(exp)
				setLoading(false)
			})
		} else {
			return;
		}

	}

	const orderCarrierUpdate = async () => {
		let firstKey = Object.keys(payloadForUpdate)[0];
		let validated = validateMaster({
			"invalid-simple-number": payloadForUpdate[firstKey],
		})

		if (validated) {
			setLoading(true)
			// Key of object
			const payload = {
				'order_id': ExistingNumberData?.order_id,
				'number_id': ExistingNumberData?.order_did[0]?.id,
				'carrier_id': payloadForUpdate[firstKey]
			}

			await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
				await dispatch(updateOrderStepTracking({
					'order_id': ExistingNumberData.order_id,
					'step_id': activeStep,
					'state_body': ExistingNumberData,
				}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
				setLoading(false)
				showToast()
				setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
				setStep(activeStep + 1)
			}).catch(exp => {
				setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
				console.log(exp)
				setLoading(false)
			})
		} else {
			return
		}

	}

	const addAccountHandler = async () => {
		let firstKey = Object.keys(payloadForUpdate)[0];
		// let validated = validateMaster({
		//   "carrier-error": payloadForUpdate[firstKey],
		// })

		// if (validated) {
		setLoading(true)
		// Key of object
		const payload = {
			'order_id': ExistingNumberData?.order_id,
			'number_id': ExistingNumberData?.order_did[0]?.id,
			[firstKey]: payloadForUpdate[firstKey]
		}
		console.log(payload, 'why error')
		await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(response => {

			setLoading(false)
			showToast()
			setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
			setStep(activeStep + 1)
		}).catch(exp => {
			console.log(exp)
			// setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
			setLoading(false)
		})
		// } else {
		//   return
		// }

	}

	const didVerification = async () => {
		let validated = validateMaster({
			"did_verified": ExistingNumberData.did_verified,
		}, "Please Verify DID")

		if (validated) {

			setLoading(true)
			const payload = {
				'order_id': ExistingNumberData?.order_id,
				'number_id': ExistingNumberData?.order_did[0]?.id,
				'is_verified': true
			}
			await dispatch(verifyDid(payload, ExistingNumberData.userData.token)).then(response => {
				setLoading(false)
				showToast()
				setStep(activeStep + 1)

			}).catch(exp => {
				console.log(exp)
				setLoading(false)
			})
		}
	}

	const savePlan = async () => {
		setLoading(true)
		let validated = validateMaster({
			'order_id': ExistingNumberData?.order_id,
			'order_number_id': ExistingNumberData?.order_did[0].id,
			'plan_id': ExistingNumberData?.plan,
			'plan_rate_id': ExistingNumberData?.plan_rate_id
		}, "Please Verify DID")


		const payload = {
			'id': ExistingNumberData?.order_subscribtion_id,
			'order_id': ExistingNumberData?.order_id,
			'order_number_id': ExistingNumberData?.order_did[0].id,
			'plan_id': ExistingNumberData?.plan,
			'plan_rate_id': ExistingNumberData?.plan_rate_id
		}

		/**
		 * Number destination email or forwarding numbers will be added after order is completed.
		 * Thats why this whole section is now commented out
		 * @author Sharique Ahmed
		 */

		// const {
		// 	voicemail: hasVoicemail,
		// 	text_message: hasTextMessage,
		// 	forwarding: hasForwarding
		// } = ExistingNumberData.plan_details

		// if (hasTextMessage) {

		// 	if (!validateEmail(ExistingNumberData?.plan_text_email)) {

		// 		showToast('error', "Please enter a valid email address")
		// 		setLoading(false);
		// 		return false;
		// 	}
		// 	const newPayload = {};
		// 	if (ExistingNumberData.plan_text_email_id) {

		// 		newPayload['id'] = ExistingNumberData?.plan_text_email_id;
		// 	}
		// 	newPayload['email'] = ExistingNumberData?.plan_text_email
		// 	newPayload['order_number_id'] = ExistingNumberData?.order_did[0]?.id;
		// 	await dispatch(deleteDidService(newPayload, ExistingNumberData?.userData?.token)).then(resp => {
		// 		if (resp['bool']) {
		// 			setExistingNumberData({ ...ExistingNumberData, "plan_text_email_id": resp.result.id })
		// 		}
		// 	}).catch(exp => {
		// 		console.log(exp)
		// 	});
		// }

		// if (hasVoicemail) {

		// 	if (!validateEmail(ExistingNumberData?.plan_voice_email)?.length) {

		// 		showToast('error', "Please enter a valid email address")
		// 		setLoading(false);
		// 		return false;
		// 	}
		// 	const newPayload = {};
		// 	if (ExistingNumberData.plan_voice_email_id) {
		// 		newPayload['id'] = ExistingNumberData?.plan_voice_email_id;
		// 	}
		// 	newPayload['email'] = ExistingNumberData?.plan_voice_email
		// 	newPayload['order_number_id'] = ExistingNumberData?.order_did[0]?.id

		// 	await dispatch(saveVoiceMail(newPayload, ExistingNumberData?.userData?.token)).then(resp => {
		// 		if (resp['bool']) {
		// 			setExistingNumberData({ ...ExistingNumberData, "plan_voice_email_id": resp.result.id })
		// 		}
		// 	}).catch(exp => {
		// 		console.log(exp)
		// 	});
		// }

		// if (hasForwarding) {

		// 	if (ExistingNumberData?.plan_forward_number_carrier?.value == "") {
		// 		setExistingNumberData({ ...ExistingNumberData, "ready": false })
		// 		showToast('error', "Carrier field is required")
		// 		setLoading(false);
		// 		return false;
		// 	}

		// 	if (ExistingNumberData?.plan_forward_number == "") {
		// 		setExistingNumberData({ ...ExistingNumberData, "ready": false })
		// 		showToast('error', "Number is required")
		// 		setLoading(false);
		// 		return false;
		// 	}

		// 	const newPayload = {};
		// 	if (ExistingNumberData.plan_forward_number_id) {
		// 		newPayload['id'] = ExistingNumberData?.plan_forward_number_id;
		// 	}

		// 	newPayload['forward_number'] = ExistingNumberData?.plan_forward_number
		// 	newPayload['order_number_id'] = ExistingNumberData?.order_did[0]?.id;
		// 	newPayload['carrier_id'] = ExistingNumberData?.plan_forward_number_carrier?.value;
		// 	newPayload['country_id'] = ExistingNumberData.plan_forward_number_country_id ?? 230;
		// 	await dispatch(saveForwardNumber(newPayload, ExistingNumberData?.userData?.token)).then(resp => {
		// 		if (resp['bool']) {
		// 			setExistingNumberData({ ...ExistingNumberData, "plan_forward_number_id": resp.result.id })
		// 		}
		// 	}).catch(exp => {
		// 		console.log(exp)
		// 	});
		// }

		if (validated) {
			await dispatch(orderDidUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
				if (response?.result?.id) {
					setExistingNumberData({ ...ExistingNumberData, "order_subscribtion_id": response.result.id })
				}

				await dispatch(updateOrderStepTracking({
					'order_id': ExistingNumberData.order_id,
					'step_id': activeStep,
					'state_body': ExistingNumberData,
				}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))

				setLoading(false)
				showToast()
				setStep(activeStep + 1)

			}).catch(exp => {
				console.log(exp)
				setLoading(false)
			})
		}
	}

	const savePlanBulk = async () => {
		// setLoading(true)

		const payload = payloadForUpdate.map((row, index) => {
			const validated = validateMaster({
				"plan_id": row.planId,
				"plan_rate_id": row.planRateId
			})

			if (validated) {
				setLoading(true)
				return {
					"order_id": row.order_id,
					"order_number_id": row.id,
					"plan_id": row.planId,
					"plan_rate_id": row.planRateId,
					"add_ons": row.addOns,
					"forward_number": row?.serviceDetails?.plan_forward_number,
					"carrier_id": row?.serviceDetails?.plan_forward_number_carrier?.value,
					"country_id": row?.serviceDetails?.plan_forward_number_country_id,
					"voice_email": row?.serviceDetails?.plan_voice_email,
					"text_email": row?.serviceDetails?.plan_text_email,
					"plan_forward_number_id": row?.serviceDetails?.plan_forward_number_id,
					"plan_voice_email_id": row?.serviceDetails?.plan_voice_email_id,
					"plan_text_email_id": row?.serviceDetails?.plan_text_email_id
				}
			}

		})
		await dispatch(bulkPlanForDId(payload, ExistingNumberData.userData.token)).then(async (response) => {

			const tempArray = [...ExistingNumberData.more_numbers_plans];
			response.result?.map((apiResponse, index) => {
				// console.log(tempArray, apiResponse)
				tempArray[index].serviceDetails = {
					...tempArray[index].serviceDetails,
					"plan_forward_number_id": apiResponse?.forward_number?.id ?? null,
					"plan_voice_email_id": apiResponse?.voice_mail?.id ?? null,
					"plan_text_email_id": apiResponse?.text_message_email?.id ?? null
				}
			})

			console.log(tempArray)

			setExistingNumberData({
				...ExistingNumberData,
				"more_numbers_plans_response": response.result,
				"more_numbers_plans": tempArray
			})
			await dispatch(updateOrderStepTracking({
				'order_id': ExistingNumberData.order_id,
				'step_id': activeStep,
				'state_body': ExistingNumberData,
			}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
			setLoading(false)
			showToast()
			setStep(activeStep + 1)

		}).catch(exp => {
			console.log(exp)
			setLoading(false)
		})
	}

	const subscrptionAddOn = async () => {
		setLoading(true)
		const payload = {
			'id': ExistingNumberData.order_subscribtion_id,
			'add_ons': ExistingNumberData?.active_add_ons
		}
		await dispatch(orderDidAddOn(payload, ExistingNumberData.userData.token)).then(async response => {
			await dispatch(updateOrderStepTracking({
				'order_id': ExistingNumberData.order_id,
				'step_id': activeStep,
				'state_body': ExistingNumberData,
			}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
			setLoading(false)
			showToast()
			setStep(activeStep + 1)

		}).catch(exp => {
			console.log(exp)
			setLoading(false)
		})
	}

	const bussniessHandler = async () => {

		setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
		if (ExistingNumberData.authorized_first_name == '') {
			setExistingNumberData({ ...ExistingNumberData, error: ['first_name'], processing: false, ready: false })
		}
		if (ExistingNumberData.authorized_last_name == '') {
			setExistingNumberData({ ...ExistingNumberData, error: ['last_name'], processing: false, ready: false })
		}
		if (ExistingNumberData.authorized_first_name != '' && ExistingNumberData.authorized_last_name != '') {
			if (ExistingNumberData.is_business_account && ExistingNumberData.business_name == '') {
				setExistingNumberData({ ...ExistingNumberData, error: ['business_name'], processing: false, ready: false })
			}
			else {
				setLoading(true)
				const payload = {
					"order_id": ExistingNumberData.order_id,
					"is_company": ExistingNumberData.is_business_account,
					"company_name": ExistingNumberData.business_name,
					"authorized_first_name": ExistingNumberData.authorized_first_name,
					"authorized_last_name": ExistingNumberData.authorized_last_name,
				}
				await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
					await dispatch(updateOrderStepTracking({
						'order_id': ExistingNumberData.order_id,
						'step_id': activeStep,
						'state_body': ExistingNumberData,
					}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
					setLoading(false)
					showToast()
					setStep(activeStep + 1)

				}).catch(exp => {
					console.log(exp)
					setLoading(false)
				})
			}
		}
	}

	const addMoreDids = async () => {
		setLoading(true)
		// console.log(ExistingNumberData.how_many_number_you_have_on_the_account)
		var validated = true;
		ExistingNumberData?.how_many_number_you_have_on_the_account?.map((data, index) => {
			if (unformatedPhone(data?.number).length != 11) {
				validated = false;
				setExistingNumberData({
					...ExistingNumberData, error: ["error"], processing: false, ready: false
				})
			}
		})
		if (validated) {
			const additionalNumbers = ExistingNumberData.how_many_number_you_have_on_the_account
			const payload = additionalNumbers.map((details, index) => {
				return { ...details, 'order_id': ExistingNumberData?.order_id, 'country_id': ExistingNumberData.first_number.countryId }
			});

			await dispatch(orderDIDCreate(payload, ExistingNumberData.userData.token)).then(async (response) => {
				await dispatch(updateOrderStepTracking({
					'order_id': ExistingNumberData.order_id,
					'step_id': activeStep,
					'state_body': ExistingNumberData,
				}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))

				setLoading(false)
				showToast()
				setExistingNumberData({ ...ExistingNumberData, 'more_numbers_plans': response.result })
				setStep(activeStep + 1)

			}).catch(exp => {
				console.log(exp)
				setLoading(false)
			})
			// setExistingNumberData({ ...ExistingNumberData, 'response_of_add_more': [...rowResponse] })
		}
		else {
			setLoading(false)
		}
		// if(ExistingNumberData.how_many_number_you_have_on_the_account.length > 0)
		// {
		//   setExistingNumberData({
		// 		...ExistingNumberData, error: [], processing: false, ready: false
		// 		 })
		// }
		// if(moreNumbers[0] == '')
		// {
		//   setExistingNumberData({
		// 		...ExistingNumberData, error: [], processing: false, ready: false
		// 		 })
		// }
		// else{

		// }



	}

	const saveSignature = async () => {
		let validated = validateMaster({
			"confirm_signature": ExistingNumberData.signature
		})
		if (validated) {
			setLoading(true)
			const payload = {
				"order_id": ExistingNumberData.order_id,
				"signature": ExistingNumberData.signature,
				"signature_text": ExistingNumberData.signature_text,
			}
			await dispatch(orderSignature(payload, ExistingNumberData.userData.token)).then(async response => {
				await dispatch(updateOrderStepTracking({
					'order_id': ExistingNumberData.order_id,
					'step_id': activeStep,
					'state_body': ExistingNumberData,
				}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
				setLoading(false)
				showToast()
				setStep(activeStep + 1)

			}).catch(exp => {
				console.log(exp)
				setLoading(false)
			})
		}


	}


	const existingNumberOrder = async () => {

		if (action === 'questions-for-more-number') {

			setLoading(true)
			let check = Object.keys(payloadForUpdate)[0];
			let validated = false;
			validated = validateMaster({
				"question": payloadForUpdate[check]
			})
			if (validated) {

				setExistingNumberData({
					...ExistingNumberData, error: [], processing: false, ready: false,
					'how_many_number_you_have_on_the_account':
						ExistingNumberData.are_there_any_more_number_that_you_want_to_port === "YES" ? [
							{
								number: "",
								type: ExistingNumberData?.is_this_a_mobile_number === "YES" ? "mobile" : ExistingNumberData?.use_number_only_to_send_receive_faxes === "YES" ? "fax" : "landline",
								countryCode: ""
							}
						] : []
				})
				await dispatch(updateOrderStepTracking({
					'order_id': ExistingNumberData.order_id,
					'step_id': activeStep,
					'state_body': ExistingNumberData,
				}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
				setLoading(false)
				setStep(activeStep + 1)
			}
			else {
				setLoading(false)
				return
			}

		} else if (action === 'did-update') {

			updateNewOrder()
		} else if (action === 'did-verification') {

			didVerification()
		} else if (action === 'order-carrier-update') {

			orderCarrierUpdate()
		} else if (action === 'account-modification') {

			addAccountHandler()
		} else if (action === 'add-more-did') {

			addMoreDids()
		} else if (action === 'bussniess-account') {

			bussniessHandler()
		} else if (action === 'save-plan-bulk') {

			savePlanBulk()
		} else if (action === 'save-plan') {

			savePlan()
		} else if (action === 'main-add-on') {

			subscrptionAddOn()
		} else if (action === 'save-signature') {

			saveSignature()
		} else {
			validateGeneral()
			setStep(activeStep + 1)
		}
	}
	function prevHandle() {
		setStep(activeStep - 1)
		setExistingNumberData({
			...ExistingNumberData, error: [], processing: false, ready: false
		})
	}
	const showToast = (type = "success", msg = "Your Data has been saved") => {
		toast[type](msg, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light", });
	}

	return (
		<>
			<div className={centerProceed ? "footer justify-content-center" : "footer"} >
				{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
				{login &&
					<div className="d-flex flex-column align-items-center me-lg-3">
						<button type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
							Login&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
						</button>
						<div className="pe">
							&nbsp;
							{/* <img src="/images/forms/icon-enter.png" alt="" /> */}
						</div>
					</div>
				}
				<div className="d-flex flex-column align-items-center">
					{!dependency ?
						<button type="button" disabled={loading} onClick={() => cf()} className="proceed-btn mb-2">
							{loading ?
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								:
								final ? "Submit" : "Proceed"
							}

						</button>
						:
						<RoundedButton
							loading={disabledViaForm || loading}
							loadingText={loadingText}
							onClick={() => !ExistingNumberData.error.length ? existingNumberOrder() : ""}
							showLoader={false}

						>
							{final ? "Submit" : "Proceed"}
						</RoundedButton>
					}
					{/* <p  "m-0 p-0">{checkAddress ? 'Invalid Address' : ''}</p>  */}
					<div className="pe">
						Press Enter
						<img src="/images/forms/icon-enter.png" alt="" />
					</div>
				</div>

			</div>
			{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
			<button type="button" data-title="Previous" onClick={prevHandle} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
				<img src="/images/forms/icon-prev.png" alt="" />
			</button>
			{/* <a className="next-step" onclick="form.steps('next')"> */}
			{/* <button type="button" data-title="Next" onClick={() => setStep(activeStep + 1)} className="next-step elaboration right"> */}
			<button type="button" data-title="Next" onClick={() => !ExistingNumberData.error.length ? existingNumberOrder() : ""} className="next-step elaboration right">
				<img src="/images/forms/icon-next.png" alt="" />
			</button>
		</>
	)
}

export default FormFooter
