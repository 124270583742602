import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { CoverageContext } from "contexts/FormsContext";
import useAuth from "hooks/useAuth";
import FormFooter from "./FormFooter";
import ValidationError from "components/Order/ValidationError";
import RoundedButton from "components/Buttons/RoundedButton";
import ReactInputMask from "react-input-mask";

const Initial = ({ setStep, setError = () => { }, activeStep, setProcessing }) => {
	const { coverage, setCoverage } = useContext(CoverageContext);
	const { user } = useAuth();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			firstname: "",
			lastname: "",
			email: "",
			phone: "",
		},
	});

	const onSubmit = (data) => {
		setCoverage((prev) => ({ ...prev, ...data }));
		localStorage.setItem('coverageData', JSON.stringify({...data}))
		setStep(activeStep + 1)
	};

	useEffect(() => {
		const cachedForm = JSON.parse(localStorage.getItem("coverageData"));
		const initialData = {
			firstname: cachedForm?.firstname || user?.first_name || "",
			lastname: cachedForm?.lastname || user?.last_name || "",
			email: cachedForm?.email || user?.email || "",
			phone: cachedForm?.phone || "",
		};

		Object.entries(initialData).forEach(([key, value]) => {
			setValue(key, value);
		});

		setCoverage((prev) => ({ ...prev, ...initialData }));
	}, [setValue, setCoverage, user]);

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<h5 className="mb-2 sm-heading">PORTABILITY CHECK</h5>
				<h2 className="lg-heading mb-5">
					You're almost there! Let's start by telling us your name and email
				</h2>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row mb-lg-2">
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										{...register("firstname", { required: "First name is required" })}
										className="input-field"
										placeholder="First Name"
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										{...register("lastname", { required: "Last name is required" })}
										className="input-field"
										placeholder="Last Name"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row mb-lg-2">
						<div className="col">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-at icon-c" aria-hidden="true"></i>
									<input
										{...register("email", {
											required: "Email is required",
											pattern: {
												value: /\S+@\S+\.\S+/,
												message: "Invalid email address",
											},
										})}
										className="input-field"
										placeholder="Email"
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-phone icon-c" aria-hidden="true"></i>
									<ReactInputMask
										placeholder="Phone number"
										mask="+1 (999) 999-9999"
										{...register("phone")}
									/>
								</div>
							</div>
						</div>
					</div>

					{/* From Footer */}
					<>
						<div className={"footer"} >
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loadingText={'Proceed'} showLoader={false}>
									Proceed
								</RoundedButton>
								{/* <div className="pe">
									Press Enter
									<img src="/images/forms/icon-enter.png" alt="" />
								</div> */}
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
					{/* <FormFooter
						setProcessing={setProcessing}
						setError={setError}
						action={"create"}
						activeStep={activeStep}
						setStep={setStep}
					/> */}
				</form>
			</div>
		</>
	);
};

export default Initial;

