import { CoverageContext } from "contexts/FormsContext";
import useAuth from "hooks/useAuth";

import React, { useState } from "react";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { coverageAccountUpdate, coverageCreate, coverageSignUp, coverageUpdate } from "redux/actions/CoverageActions";

const FormFooter = ({
	setStep,
	activeStep,
	setError = () => { },
	centerProceed,
	disabled = false,
	action,
	final,
	login,
	setProcessing = () => { }
}) => {

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false)
	const { coverage, setCoverage } = useContext(CoverageContext)
	const { isAuthenticated } = useAuth();

	const onClickHandler = async () => {

		if (action === 'create') {

		
		} else if (action === 'update') {

			setProcessing(true)
			setLoading(true)
			const countryCode = [];
			const abc = [];
			coverage?.port?.map((value, key) => abc.push(value.number))
			coverage?.port?.map((value, key) => countryCode.push(value.countryCode))
			const payload = {
				"fname": coverage.firstname,
				"lname": coverage.lastname,
				"company": "",
				"email": coverage.email,
				"phone": "",
				"fax": "",
				"address": "",
				"state": "",
				"city": "",
				"zip": "",
				"port": abc.toString(),
				"countryCode": countryCode.toString(),
				"country": coverage.coverage,
				"port_country_id": "",
				"interest": coverage.accountFor,
				"msg": JSON.stringify(coverage?.goals),
				"id": coverage.id,
				"checkPortability": false
			}

			await dispatch(coverageUpdate(payload)).then(response => {

				if (response.bool) {
					setStep(activeStep + 1)
					setProcessing(false)
					setLoading(false)
				}
			}).catch(exp => {
				console.log(exp)
				setProcessing(false)
				setLoading(false)
			});

		} else if (action === 'update-with-check') {

			const validate = coverage.port?.map((row, index) => {
				return row.number.length >= 11

			})
			if (validate.includes(false)) {
				console.log(setError("Number is not valid"))
				// return toast.error('Number is not valid')
				setError("Number is not valid")
				return false
			}

			setProcessing(true)
			setLoading(true)
			const countryCode = [];
			const abc = [];
			const phoneTypeArray = [];
			coverage?.port?.map((value, key) => abc.push(value.number))
			coverage?.port?.map((value, key) => countryCode.push(value.countryCode))
			coverage?.port?.map((value, key) => phoneTypeArray.push(value.type))
			const payload = {
				"fname": coverage.firstname,
				"lname": coverage.lastname,
				"company": "",
				"email": coverage.email,
				"phone": "",
				"fax": "",
				"address": "",
				"state": "",
				"city": "",
				"zip": "",
				"port": abc.toString(),
				"countryCode": countryCode.toString(),
				"country": coverage.coverage,
				"port_country_id": "",
				"interest": coverage.accountFor,
				"msg": JSON.stringify(coverage?.goals),
				"id": coverage.id,
				"checkedBy": coverage.checkedBy || "",
				"phone_type": phoneTypeArray.toString(),
				"checkPortability": true
			}

			await dispatch(coverageUpdate(payload)).then(response => {
				if (response.bool) {
					const numberResponse = [];
					response.result.forEach(element => {
						const rawResponse = []
						let portable = false
						// get raw response for more accuracy
						const payload = JSON.parse(element.response)

						Object.values(payload).forEach((value, key) => {
							if (value.result?.portable === true) {
								portable = true
							}
							rawResponse['portable'] = portable
							// rawResponse['response'] = value.result?.portable
						})

						rawResponse['phoneNumber'] = element.phone_no
						numberResponse.push(rawResponse)
					});
					setCoverage({ ...coverage, portabilityResponse: numberResponse })
					setProcessing(false)
					setLoading(false)
					setStep(activeStep + 1)
				}
			}).catch(exp => {
				console.log(exp)
			});
			setProcessing(false)
			setLoading(false)

		} else if (action === 'signup') {

			if (coverage.userdata) {
				signupUpdate()
			} else {
				signupStep()
			}

		} else if (action === 'signup-update') {

			signupUpdate()

		} else {
			setStep(activeStep + 1)
		}

	}

	const signupStep = async () => {
		setProcessing(true)
		setLoading(true)

		const payload = {
			'first_name': coverage?.firstname,
			'last_name': coverage?.lastname,
			'email': coverage?.email,
			'password': coverage?.password,
		}
		await dispatch(coverageSignUp(payload)).then(response => {
			if (response.bool) {
				console.log(response.result)
				setCoverage({ ...coverage, 'userdata': response.result, 'checkedBy': response?.result?.id })
				setLoading(false)
				setProcessing(false)
				setStep(activeStep + 1)
			}
		}).catch(errr => {

			console.log(errr)
			toast.error(errr.message)
			setLoading(false)
			setProcessing(false)
		})
	}

	const signupUpdate = async () => {
		setProcessing(true)
		setLoading(true)
		const payload = {
			'id': coverage.userdata.id,
			'first_name': coverage?.firstname,
			'last_name': coverage?.lastname,
			'email': coverage?.email,
			'password': coverage?.password,
			'phone': coverage?.first_phone?.phoneNumber,
			'user_type': coverage?.addMorePPL === "YES" ? "business" : "personal"
		}

		await dispatch(coverageAccountUpdate(payload, coverage.userdata.token)).then(response => {
			if (response.bool) {
				setLoading(false)
				setProcessing(false)
				setStep(activeStep + 1)
			}
		}).catch(errr => {

			console.log(errr)
			toast.error(errr.message)
			setLoading(false)
			setProcessing(false)
		})

	}

	return (
		<>
			<div className={centerProceed ? "footer justify-content-center align-items-baseline" : "footer"} >
				{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
				{login &&
					<div className="d-flex flex-column align-items-center me-lg-3">
						{isAuthenticated ?

							<Link className="nav-link" to={'/customer/dashboard'}>
								<button type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
									Dashboard&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
								</button>
							</Link>

							:
							<Link className="nav-link" to={'/login'}>
								<button disabled={disabled} type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
									Login&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
								</button>
							</Link>

						}
					</div>
				}
				{
					((isAuthenticated) && (activeStep === 2)) ? "" :
						<div className="d-flex flex-column align-items-center">
							{login ?
								<Link className="nav-link" to={'/signup'}>
									<button type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
										Proceed&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
									</button>
								</Link>
								:
								<>
									<button disabled={coverage.error || loading || disabled} type="button" onClick={onClickHandler} className="proceed-btn mb-2">
										{
											loading ?
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
												:
												final ? "Submit" : "Proceed"
										}
									</button>
									<div className="pe">
										Press Enter
										<img src="/images/forms/icon-enter.png" alt="" />
									</div>
								</>
							}
						</div>
				}

			</div>
			{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
			<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
				<img src="/images/forms/icon-prev.png" alt="" />
			</button>
			{/* <a className="next-step" onclick="form.steps('next')"> */}
			{/* <button type="button" data-title="Next" onClick={() => setStep(activeStep + 1)} className="next-step elaboration right"> */}
			<button type="button" data-title="Next" disabled={coverage.error || loading} onClick={onClickHandler} className="next-step elaboration right">
				<img src="/images/forms/icon-next.png" alt="" />
			</button>
		</>
	)
}

export default FormFooter
