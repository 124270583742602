import { NewNumberContext } from 'contexts/FormsContext'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { savePaymentProfile, updateOrderStepTracking } from 'redux/actions/OrderAction'
import ValidationError from 'components/Order/ValidationError';
import React, { useContext, useState } from "react";
import Cards from '@snowpak/react-credit-cards';
import "../../assets/css/credit-card.css";
import '@snowpak/react-credit-cards/lib/styles-compiled.css'
import "../../assets/css/form-style.css";
import '../../assets/css/payment-profile.scoped.css'
import RoundedButton from "components/Buttons/RoundedButton";
import TermsAndCondition from 'pages/order/order-components/TermsAndCondition'
import { useHistory } from 'react-router-dom'

const NewPaymentProfile = ({ toggleStep = () => { } }) => {
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)
	const paymentProfileList = useSelector(state => state.payment.creditCardList);
	const history = useHistory();
	const previousStepUrl = '/new-number-order/service-address';
	const nextStepUrl = '/new-number-order/summary';
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false)

	const addNewClickHandler = (e) => {
		toggleStep("select-card")
	}

	const { formState: { errors }, handleSubmit, register, watch, setValue, clearErrors, reset } = useForm({
		defaultValues: {
			'select_card': NewNumberData?.select_card ? NewNumberData.select_card : "",
			'name_on_card': NewNumberData.name_on_card ? NewNumberData.name_on_card : NewNumberData?.firstname + " " + NewNumberData?.lastname,
			'card_number': NewNumberData.card_number ? NewNumberData.card_number : "",
			'expiry': NewNumberData.expiry ? NewNumberData.expiry : "",
			'expiryyear': NewNumberData.expiryyear ? NewNumberData.expiryyear : "",
			'cvc': NewNumberData.cvc ? NewNumberData.cvc : "",
			'email': NewNumberData.email ? NewNumberData.email : "",
			'address': NewNumberData?.service_address_where_the_phone_physically_installed?.street_address ?? "",
			'city': NewNumberData?.service_address_where_the_phone_physically_installed?.city ?? "",
			'state': NewNumberData?.service_address_where_the_phone_physically_installed?.state?.label ?? "",
			'zip': NewNumberData?.service_address_where_the_phone_physically_installed?.zip_code ?? "",
			'country': NewNumberData?.service_address_where_the_phone_physically_installed?.country?.label ?? "",
			// 'terms_condition': NewNumberData.terms_condition === "" ? true : "",
			'focus': ''
		}
	})

	//function to add space after every 4 character in card number
	const addSpace = (e) => {
		if (watch('card_number').length === 4 || watch('card_number').length === 9 || watch('card_number').length === 14)
			setValue('card_number', watch('card_number').replace(/\W/gi, "").replace(/(.{4})/g, "$1 "))
	}

	const removeSpecial = (e) => {
		var invalidChars = ["-", "+", "e", "E", " ", "."];
		if (invalidChars.includes(e.key)) {
			e.preventDefault();
		}
	}

	const submitHandler = async (form) => {

		setLoading(true)
		let payload = {
			'user_id': NewNumberData.user_id,
			'order_id': NewNumberData.order_id,
			'card_number': form.card_number,
			'name_on_card': form.name_on_card,
			'expiry': form.expiry,
			'expiryyear': form.expiryyear,
			'cvc': form.cvc
		}

		if (NewNumberData?.business_name) {
			payload['company'] = NewNumberData.business_name
		}

		await dispatch(savePaymentProfile(payload, NewNumberData.userData.token)).then(async response => {

			const apiResponse = response?.result?.paymentProfileCreate?.result;
			// console.log(apiResponse)
			setNewNumberData({
				...NewNumberData,
				'name_on_card': form.name_on_card,
				'card_number': form.card_number,
				'expiry': form.expiry,
				'expiryyear': form.expiryyear,
				'cvc': form.cvc,
				'terms_condition': form.terms_condition,
				'customerProfileDetails': response?.result?.userKey.value,
				'paymentProfileIdDetails': response?.result?.paymentProfileCreate.result,
				'error': [],
				'ready': false
			})
			setLoading(false)
			toast.success('Your Data has been saved', { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });

			history.push(nextStepUrl);

		}).catch(exp => {
			console.log(exp)
			setLoading(false)
			toast.error(exp.message)
			setNewNumberData({ ...NewNumberData, error: [], ready: false })
		})
		setLoading(false)

	}

	return (
		<>
			<ValidationError errors={errors} />
			<form onSubmit={handleSubmit(submitHandler)}>

				<div className="row">
					<div className="col-12">
						<h2 className="lg-heading mb-2">New Payment Method</h2>
					</div>
				</div>

				{
					paymentProfileList?.result?.length > 0 && (
						<div className="row">
							<div className="col-12">
								{/* <h4 className="heading my-2"><span onClick={addNewClickHandler}>Select a credit card below </span> Or Add a New Card </h4> */}
								<div className='choose_btw_payment mt-4'>
									<div className='left button'>
										<div onClick={addNewClickHandler} className='only_text'>
											Use Existing Cards
										</div>
									</div>
									<div className='or_separator'></div>
									<div className='right button active'>
										<div className='only_text' >
											Add a New Card
										</div>
									</div>
								</div>
							</div>
						</div>
					)
				}

				<div className="row">
					<div className="col-md-12">
						<div>
							<div className="row">
								<div className="col-6">
									<div className="credit-card my-2">
										<Cards locale={{ valid: "Expires" }} placeholders={{ name: "FULL NAME" }} cvc={watch("cvc")}
											expiry={watch("expiry")} expiryyear={watch("expiryyear")} focused={watch("focus")}
											name={watch("name_on_card")} number={watch("card_number")} />
									</div>
								</div>
								<div className="col-6">
									<div className="row">
										<div className="form-group my-2 col-lg-12 col-12">
											<label htmlFor="cardHolder" className="card-label">
												Card holder
											</label>
											<input spellCheck="false" {...register('name_on_card', {
												required: "Name on card is required",
											})}
												placeholder="John Doe" autoComplete="off" onPaste={(e) => e.preventDefault()}
												className="form-control form-control-lg"
											/>
										</div>
										<div className="form-group my-2 col-lg-12 col-12">
											<label htmlFor="cardNumber" className="card-label">
												Card Number
											</label>
											<input {...register('card_number', {
												required: "Card number is required", pattern: { value: /^[0-9 ]+$/, message: "Invalid input for card number" }, maxLength: {
													value: 22,
													message: "Invalid length"
												},
											})} onKeyDown={removeSpecial} onPaste={(e) => e.preventDefault()}
												onKeyPress={addSpace}
												className="form-control form-control-lg"
											/>
										</div>
									</div>
									<div className="row">
										<div className="date-cvv-box col-lg-6 col-12">
											<div className="expiry-class">
												<div className="form-group card-month ">
													<label htmlFor="cardMonth" className="card-label">
														Expiration Date
													</label>
													<div className="form-group my-2 d-flex flex-row w-100">
														<select id="cardMonth" data-ref="cardDate" {...register('expiry', {
															required: "Expiry month is required",
														})} className="form-control form-control-lg">
															<option value="" defaultChecked="true">
																Month
															</option>
															<option value="01">01</option>
															<option value="02">02</option>
															<option value="03">03</option>
															<option value="04">04</option>
															<option value="05">05</option>
															<option value="06">06</option>
															<option value="07">07</option>
															<option value="08">08</option>
															<option value="09">09</option>
															<option value="10">10</option>
															<option value="11">11</option>
															<option value="12">12</option>
														</select>
														<select id="cardYear" data-ref="cardDate" {...register('expiryyear', {
															required: "Expiry year is required",
														})} className="form-control form-control-lg ms-2">
															<option value="" defaultChecked="true">
																Year
															</option>
															<option value="2024">2024</option>
															<option value="2025">2025</option>
															<option value="2026">2026</option>
															<option value="2027">2027</option>
															<option value="2028">2028</option>
															<option value="2029">2029</option>
															<option value="2030">2030</option>
															<option value="2031">2031</option>
															<option value="2032">2032</option>
															<option value="2033">2033</option>
															<option value="2034">2034</option>
															<option value="2035">2035</option>
															<option value="2036">2036</option>
														</select>
													</div>
												</div>
											</div>
										</div>
										<div className="cvv-class form-group my-2 col-lg-6 col-md-12 col-sm-12 ">
											<label htmlFor="cvv" className="card-label cvv-label">
												CVV
											</label>
											<input type="number" {...register('cvc', {
												required: "CVC is required", minLength: {
													"value":
														3, "message": "CVC is too short"
												}, maxLength: { "value": 4, "message": "CVC is not valid" }
											})} onPaste={(e) => e.preventDefault()}

												className="form-control form-control-lg"
												maxLength="4"
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="row py-2">
								<div className="col-12">
									<div className='fw-bolder my-auto fs-5'>Billing address</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-6">
									<label>Email</label>
									<input {...register("email", {
										required: {
											value: true, message: "Email is required"
										},
										value: "",
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: "Invalid email address"
										}
									})} name="email" className="form-control form-control-lg" type="text" />
								</div>
								<div className="col-6">
									<label>Address</label>
									<input {...register("address", {
										maxLength: {
											value: 60, message: "Address is too long"
										},
										required: {
											value: true, message: "Address is required"
										}, value: ""
									})} name="address" className="form-control form-control-lg" type="text" />
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-3">
									<label>City</label>
									<input {...register("city", {
										required: {
											value: true, message: "City is required"
										}, value: ""
									})} name="city" className="form-control form-control-lg" type="text" />
								</div>
								<div className="col-3">
									<label>State / Province</label>
									<input {...register("state", {
										required: {
											value: true, message: "State is required"
										}, value: ""
									})} name="state" className="form-control form-control-lg" type="text" />
								</div>
								{/* </div>
								<div className="row"> */}
								<div className="col-3">
									<label>Country</label>
									<input {...register("country", {
										required: {
											value: true, message: "Country is required"
										}, value: ""
									})} name="country" className="form-control form-control-lg" type="text" />
								</div>
								<div className="col-3">
									<label>Postal code</label>
									<input {...register("zip", {
										required: {
											value: true, message: "Zip is required"
										}, value: ""
									})} name="zip" className="form-control form-control-lg" type="text" />
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="item mt-4 terms_checkbox">
										<input
											type="checkbox"
											className="custom-cb terms_inside_checkbox"
											{...register('terms_condition', {
												'required'
													: "Terms and condition field is required"
											})}
										/>
										<label onClick={() => setShowModal(true)} htmlFor="terms_condition"> I have read and I agree to the &nbsp;
											<span
												//  href="#" target="_blank"
												className="tnc_link"
											>
												Terms and Conditions
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{/* From Footer */}
				{/* Terms and condition */}
				<TermsAndCondition show={showModal} setShow={(e) => setShowModal(false)} />
				{/* Terms and condition end */}
				<>
					<div className={"footer mt-3"}>
						{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
						<div className="d-flex flex-column align-items-center">
							<RoundedButton loading={loading} showLoader={false}>
								Proceed
							</RoundedButton>
							<div className="pe">
								Press Enter
								<img src="/images/forms/icon-enter.png" alt="" />
							</div>
						</div>
					</div>
					{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
					<button type="button" data-title="Previous" onClick={() => history.push(previousStepUrl)} className={"prev-step elaboration right"}>
						<img src="/images/forms/icon-prev.png" alt="" />
					</button>
					{/* <a className="next-step" onclick="form.steps('next')"> */}
					<button type="button" data-title="Next" onClick={handleSubmit(submitHandler)} className="next-step elaboration right">
						<img src="/images/forms/icon-next.png" alt="" />
					</button>
				</>
				{/* From Footer end */}
			</form>
		</>
	)
}

export default NewPaymentProfile
