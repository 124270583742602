import parsePhoneNumberFromString, { parsePhoneNumber } from "libphonenumber-js";

export const formatPhone = (number) => {
	if (!number) {
		return
	}
	const countryCode = number.substring(0, 1);
	const npa = number.substring(1, 4);
	const nxx = number.substring(4, 7);
	const suffix = number.substring(7);
	return `+${countryCode} (${npa}) ${nxx}-${suffix}`
}

export const unformatedPhone = (number) => {
	return number.replace(/[^0-9]/g, '')
}

export const formatPhoneByCountry = (number, countryCode = 'US') => {
    if (!number) return;

    try {
        // Parse the number using the country code
        const phoneNumber = parsePhoneNumberFromString(number, countryCode);

        if (phoneNumber) {
            // Format the number based on the country
            return phoneNumber.formatInternational(); // Outputs in "+XX (XXX) XXX-XXXX" format
        }
    } catch (error) {
        console.error("Invalid phone number or country code", error);
    }

    return number; // Return original number if parsing fails
};

export const validatePhone = (number, countryCode = "US") => {
	if (!number) {
		return
	}
	var numbers = parsePhoneNumber(number, countryCode.toUpperCase())
	return (numbers.isValid()) && (numbers.country == countryCode);
}
