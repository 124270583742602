import axios from "../../axios";

export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_NUMBERS = 'GET_NUMBERS'

// 1. Get Active Countries
export const getCountries = (token) => async (dispatch) => {
    const resp = await axios.get("countries/all", {
        headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
        type: GET_COUNTRIES,
        payload: resp.data.result
    });
};

// 2. Select Country for New Number Order
export const selectCountry = async (data, token) => {
    const resp = await axios.post("new-number/select-country", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 3. Search Criteria for Numbers
export const searchNumbers = (payload) => async (dispatch) => {
    const resp = await axios.post("new-number/search", payload);
    dispatch({
        type: GET_NUMBERS,
        payload: resp.data.result?.[0]?.['TelephoneNumber'] || []
    });
    return resp.data
};

// 4. Add Selected Numbers to the Order
export const addNumbersToOrder = async (data, token) => {
    const resp = await axios.post("orders-did/create", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 5. Get Plan Categories with Rates
export const getPlanCategories = async (data, token) => {
    const resp = await axios.post("plans/category/with-plan-rates", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 6. Get Plans by Selected Category
export const getPlansByCategory = async (data, token) => {
    const resp = await axios.post("plans/by-category", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 7. Select Plan for the Order
export const selectPlan = async (data, token) => {
    const resp = await axios.post("new-number/select-plan", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 8. Get Add-ons for Customer
export const getAddOns = async (token) => {
    const resp = await axios.get("plans/add-ons-customer", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 9. Select Add-ons for Order
export const selectAddOns = async (token) => {
    const resp = await axios.get("plans/add-ons-customer", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 10. Add Forwarding Number
export const addForwardingNumber = async (data, token) => {
    const resp = await axios.post("did/forward-number", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 11. Add Voicemail
export const addVoicemail = async (data, token) => {
    const resp = await axios.post("did/voice-email", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 12. Add Text Message Email
export const addTextMessageEmail = async (data, token) => {
    const resp = await axios.post("did/text-message", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 13. Get Existing Payment Cards
export const getExistingCards = async (token) => {
    const resp = await axios.get("user/get-customer-payment-profile", {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 14. Attach Existing Card to the Order
export const attachExistingCard = async (data, token) => {
    const resp = await axios.post("user/customer-payment-profile", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 15. Create New Card and Attach to the Order
export const createNewCard = async (data, token) => {
    const resp = await axios.post("user/customer-payment-profile", data, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};

// 16. Send Order Summary Email
export const sendOrderSummaryEmail = async (id, token) => {
    const resp = await axios.post(`send-email/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return resp.data;
};
