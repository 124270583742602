import ValidationError from 'components/Order/ValidationError'
import { NewNumberContext } from 'contexts/FormsContext'
import React from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

const CreateProfile = () => {
	const history = useHistory();
	const previousStepUrl = '/new-number-order/plans';
	const nextStepUrl = '/new-number-order/payment';
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			'firstname': NewNumberData?.firstname || "",
			'lastname': NewNumberData?.lastname || "",
			'email': NewNumberData?.email || "",
		}
	});

	const submitHandler = (e) => {
		setLoading(true)
		setNewNumberData({ ...NewNumberData, ...e })
		history.push(nextStepUrl)
		setLoading(false)
	}

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>
					<h5 className="mb-2 sm-heading">ORDER</h5>
					<h2 className="lg-heading mb-5">You're almost there! Let's start by telling us your name and email</h2>
					<div className="row mb-lg-4">
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										className={errors.firstname ? "error input-field" : "input-field"}
										id="first-name"
										type="text"
										placeholder="First Name"
										{...register("firstname", { required: "First name is required" })} />
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										className={errors.lastname ? "error input-field" : "input-field"}
										id="last-name"
										type="text"
										placeholder="Last Name"
										{...register("lastname", { required: "Last name is required" })} />
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-at icon-c" aria-hidden="true"></i>
									<input
										className={errors.email ? "error input-field" : "input-field"}
										id="email"
										type="email"
										placeholder="email@gmail.com"
										{...register("email", { required: "Email is required" })} />
								</div>
							</div>
						</div>
					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">

								<button type='submit' className="proceed-btn mb-2" disabled={loading}>
									{
										loading ?
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											:
											"Proceed"
									}
								</button>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => history.push(previousStepUrl)} className={"prev-step elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="button" data-title="Next" onClick={handleSubmit(submitHandler)} className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	);
}

export default CreateProfile
