import ValidationError from 'components/Order/ValidationError';
import { NewNumberContext } from 'contexts/FormsContext';
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Select from 'react-select'
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getDivisionsByCountry } from 'redux/actions/StreetMapAction';

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        // borderBottom: '1px dotted pink',
        // color: state.isSelected ? 'red' : 'blue',
        // padding: 20,
    }),
    control: (provided) => ({
        ...provided,
        // none of react-select's styles are passed to <Control />
        height: 56,
        width: "100%",
        background: "#fff",
        paddingLeft: 50,
        display: "flex",
        alignItems: "center",
        border: "1px solid #dbdbd9",
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
    }
}


const ServiceAddress = () => {
    const dispatch = useDispatch();
    const { NewNumberData, setNewNumberData } = useContext(NewNumberContext);
    const history = useHistory();
    const { register, handleSubmit, clearErrors, watch, setValue, formState: { errors } } = useForm({
        defaultValues: {
            street_address: NewNumberData?.service_address?.street_address,
            city: NewNumberData?.service_address?.city,
            zip_code: NewNumberData?.service_address?.zip_code,
            country: NewNumberData?.service_address?.country,
            state: NewNumberData?.service_address?.state,
        },
    });
    const previousStepUrl = '/new-number-order/name-on-account';
    const nextStepUrl = '/new-number-order/payment';
    const [loading, setLoading] = useState(false);
    const divisionList = useSelector(state => state.streetMap?.divisions?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.short_code.toLowerCase() } }));
    const [googlePlaces, setGooglePlaces] = useState(NewNumberData?.service_address?.street_address)

    const handleChange = address => {
        setGooglePlaces(address)
        setValue('street_address', address)
    };

    // custom css on input focus
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleInputFocus = () => {
        setIsInputFocused(true);
    };

    const handleInputBlur = () => {
        setIsInputFocused(false);
    };


    const handleSelect = async (address) => {
        clearErrors()
        // setting stat
        setGooglePlaces(address)
        setValue('street_address', address)
        const results = await geocodeByAddress(address);
        const latLng = await getLatLng(results[0]);
        const place = await geocodeByPlaceId(results[0].place_id);
        const { long_name: postalCode = '' } = place[0].address_components.find(c => c.types.includes('postal_code')) || {};
        const { short_name: stateName = '' } = place[0].address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
        const { long_name: cityName = '' } = place[0].address_components.find(c => c.types.includes("locality")) || place[0].address_components.find(c => c.types.includes("political")) || {};

        const stateIdFromList = divisionList.filter(state => state.code.toLowerCase() === stateName.toLowerCase())[0];

        setValue('street_address', address)
        setValue('city', cityName)
        setValue('zip_code', postalCode)
        setValue('state', stateIdFromList)
    };

    const submitHandler = (data) => {
        setLoading(true);
        
        setNewNumberData({ ...NewNumberData, 'service_address': data });
        history.push(nextStepUrl);
        setLoading(false);
    }

    useEffect(() => {
        if (NewNumberData?.country == null) {
            toast.error('Please go back and select country first.', { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3500, theme: "light" })
        }

        if (NewNumberData?.country) {
            setValue('country', NewNumberData.country)
            dispatch(getDivisionsByCountry(NewNumberData.country.id));
        }
    }, [])

    return (
        <>
            <ValidationError errors={errors} />
            <div className="step">
                <form onSubmit={handleSubmit(submitHandler)}>
                    <h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
                    <h2 className="lg-heading">Please enter the service address or where you reside.</h2>
                    <div className="row signupbutton_content mb-4">
                        <div className="col-12">
                            <div className="email field">
                                <img className="icon user" src="/images/forms/home-address.png" alt="" />
                                <label htmlFor="street_address" className="w-100">Service Street Address <span className="float-end blue">(Your Physical Address)</span></label>
                                <PlacesAutocomplete
                                    searchOptions={{
                                        componentRestrictions: {
                                            country: [NewNumberData?.country?.code],
                                        }
                                    }}
                                    value={googlePlaces}
                                    onChange={(e) => { handleChange(e) }}
                                    onSelect={(e) => { handleSelect(e) }}

                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Search Places ...',
                                                    className: 'location-search-input'
                                                })}
                                            />
                                            <div className='wrapper-location w-100 position-absolute' style={{ zIndex: 999 }}>
                                                <div className="autocomplete-dropdown-container">
                                                    {loading && <div className='px-2 py-1 shadow border-bottom suggestion-item'>Loading...</div>}
                                                    {suggestions.map(suggestion => {
                                                        const className = suggestion.active
                                                            ? 'px-2 py-1 shadow border-bottom suggestion-item--active'
                                                            : 'px-2 py-1 shadow border-bottom suggestion-item';
                                                        // inline style for demonstration purpose
                                                        const style = suggestion.active
                                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                        return (
                                                            <div
                                                                {...getSuggestionItemProps(suggestion, {
                                                                    className,
                                                                    style,
                                                                })}
                                                            >
                                                                <span>{suggestion.description}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        </div>
                    </div>
                    <div className="row signupbutton_content mb-4">
                        <div className="col-12 col-md-6">
                            <div className="email field">
                                <img className="icon user" src="/images/forms/condo.png" alt="" />
                                <label htmlFor="city">City</label>
                                <input {...register('city', { required: "City is required" })} />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="email field">
                                <img className="icon user" src="/images/forms/codepost.png" alt="" />
                                <label htmlFor="zip">Zip/postal code</label>
                                <input {...register('zip_code', {
                                    required: "Zip code is required"
                                })} />
                            </div>
                        </div>
                    </div>
                    <div className="row signupbutton_content">
                        <div className="col-12 col-md-6">
                            <div className="email field">
                                <img className="icon user country" src="/images/forms/country.png" alt="" />
                                <label htmlFor="cvv">Country</label>
                                <input type="text" value={watch('country.name')} readOnly />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="email state field " style={isInputFocused ? { zIndex: 999 } : {}}>
                                <img className="icon user country" src="/images/forms/united-states-of-america.png" alt="" />
                                <label htmlFor="cvv">State/Province</label>
                                <Select
                                    options={divisionList}
                                    name="state"
                                    onChange={(e) => setValue('state', e)}
                                    className="generic-country-selects "
                                    styles={customStyles}
                                    // defaultValue={}
                                    value={watch('state')}
                                    menuPlacement="top"
                                    onFocus={handleInputFocus}
                                    onBlur={handleInputBlur}
                                />
                            </div>
                        </div>
                    </div>
                    {/* From Footer */}
                    <>
                        <div className={"footer"} >
                            {/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
                            <div className="d-flex flex-column align-items-center w-100">

                                <button type='submit' className="proceed-btn mb-2" disabled={loading}>
                                    {
                                        loading ?
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            :
                                            "Proceed"
                                    }
                                </button>
                            </div>

                        </div>
                        {/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
                        <button type="button" data-title="Previous" onClick={() => history.push(previousStepUrl)} className={"prev-step elaboration right"}>
                            <img src="/images/forms/icon-prev.png" alt="" />
                        </button>
                        {/* <a className="next-step" onclick="form.steps('next')"> */}
                        <button type="button" data-title="Next" onClick={handleSubmit(submitHandler)} className="next-step elaboration right">
                            <img src="/images/forms/icon-next.png" alt="" />
                        </button>
                    </>
                    {/* From Footer end */}
                </form>
            </div>

        </>
    )
}

export default ServiceAddress
