/* eslint-disable no-unused-vars */
import SelectNumber from './new-number/SelectNumber'
import React, { useState, useContext, useEffect } from 'react'
import { NewNumberContext, NewNumberProvider } from 'contexts/FormsContext'
import './assets/css/order.css'
import './assets/css/general.css'
import './assets/css/responsive.css'
import PaymentDetails from './new-number/PaymentDetails'
import Success from './new-number/Success'
import CreateProfile from './new-number/CreateProfile'
import AddOns from './new-number/AddOns'
import Plans from './new-number/Plans'
import { Link, Redirect, Route, Router, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import SelectFeatures from './order-components/SelectFeatures'
import Title from 'general/Title'
import Topbar from 'components/Public/Topbar'
import Footer from 'components/Public/Footer'
import NumberSidebar from 'components/Order/New-Number/NumberSidebar'
import StepCounter from 'components/Order/New-Number/StepCounter'
import SelectCountry from './new-number/SelectCountry'
import NumberResult from './new-number/NumberResult'
import Summary from './new-number/Summary'
import NameOnAccount from './new-number/NameOnAccount'
import ServiceAddress from './new-number/ServiceAddress'

const NewNumber = () => {
	const { path, url } = useRouteMatch();
	const [sidebarStatus, setSidebarStatus] = useState(false)
	const [isSticky, setIsSticky] = useState(false)
	window.onscroll = function () {
		if (window.scrollY > 87) {
			setIsSticky(true)
		} else {
			setIsSticky(false)
		}
	}

	var header = document.getElementById("public-sidebar");
	var sticky = header?.offsetTop;

	const NewTitle = [
		"Country",
		"Number",
		"Plan Category",
		"Plan & Plan Rate",
		"Add Forwarding/Voicemail/Text Message email",
		"AddOns",
		"Payment",
		"Summary",
	]

	useEffect(() => {

	}, [])

	return (
		<>
			<Title title={"Order Form"} />
			<NewNumberProvider>
				<Topbar />
				<div className="stepsnavigation">
					<StepCounter stepTitle={NewTitle} />
				</div>
				<div id='public-sidebar' className={(sidebarStatus ? "public-sidebar " : "public-sidebar collapsed") + ' ' + (isSticky ? "sticky" : "")}>
					{sidebarStatus &&
						<h4 className='text-primary-c mb-4'><i className="fa fa-history"></i> Form History</h4>
					}
					{sidebarStatus &&
						<NumberSidebar />
					}
				</div>
				<div className="order_body">
					<div className="main wizard clearfix">
						<Switch>
							<Route path={`${path}/country`} exact component={SelectCountry} />
							<Route path={`${path}/select-number`} exact component={SelectNumber} />
							<Route path={`${path}/number-result`} exact component={NumberResult} />
						
							<Route path={`${path}/creating-profile`} exact component={CreateProfile} />
							<Route path={`${path}/name-on-account`} exact component={NameOnAccount} />
							<Route path={`${path}/service-address`} exact component={ServiceAddress} />
						
							<Route path={`${path}/plans`} exact component={Plans} />
							<Route path={`${path}/payment`} exact component={PaymentDetails} />
							<Route path={`${path}/summary`} exact component={Summary} />
							{/* <Route path={`${path}/select-features`} exact component={SelectFeatures} /> */}

							{/* Catch-all 404 Route */}
							<Route>
								<div className="step">
									<h2 className="lg-heading mb-5 fw-bold lg-heading mb-5 text-center">404 - Page Not Found</h2>
									<p>The page you are looking for does not exist.</p>
									<Link to={path} style={{ textDecoration: 'underline', color: 'blue' }}>
										Go back to the main order page
									</Link>
								</div>
							</Route>
						</Switch>
					</div>
				</div>
				<Footer />
			</NewNumberProvider>
		</>
		// 	<>
		// 		{!NewNumberData?.userData ? //will change after auth
		// 			activeStep === 1 &&
		// 			<CreateProfile activeStep={activeStep} setStep={setStep} />
		// 			:
		// 			activeStep === 1 &&
		// 			<SelectNumber activeStep={activeStep} setStep={setStep} />
		// 		}
		// 		{/* {activeStep === 2 &&
		//     <SelectFeatures activeStep={activeStep} setStep={setStep} />
		//   } */}
		// 		{activeStep === 2 &&
		// 			<Plans activeStep={activeStep} setStep={setStep} />
		// 		}
		// 		{activeStep === 3 &&
		// 			<AddOns activeStep={activeStep} setStep={setStep} />
		// 		}
		// 		{/* {activeStep === 4 &&
		//     <Signature activeStep={activeStep} setStep={setStep} />
		//   } */}
		// 		{activeStep === 4 &&
		// 			<PaymentDetails activeStep={activeStep} setStep={setStep} />
		// 		}
		// 		{activeStep === 5 &&
		// 			<OrderSummary activeStep={activeStep} setStep={setStep} />
		// 		}
		// 		{activeStep === 6 &&
		// 			<Success />
		// 		}
		// 	</>
	)
}

export default NewNumber
