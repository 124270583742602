/* eslint-disable jsx-a11y/anchor-is-valid */
import Footer from 'components/Public/Footer'
import Topbar from 'components/Public/Topbar'
import Title from 'general/Title'
import React, { useState, useEffect, useRef } from 'react'
import { ExistingNumberProvider, NewNumberProvider } from 'contexts/FormsContext'
import './assets/css/order.css'
import './assets/css/general.css'
import './assets/css/responsive.css'
import NumberSidebarData from './order-components/NumberSidebarData'
import Initial from './order-components/Initial'
import NewNumber from './NewNumber'
import ExistingNumber from './ExistingNumber'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { OrderStepDataById } from 'redux/actions/OrderAction'
const Order = () => {

	const NewTitle = [
		"",
		"New Number",
		// "Features",
		"Plans",
		"AddOns",
		// "Signature",
		"Payment",
	]
	const ExistingTitle = [
		"",
		"Number",
		"Mobile?",
		"Verification",
		"Quicker Porting?",
		"Fax?",
		"Carrier",
		"Account Number",
		"BTN Question",
		"BTN Number",
		// "Question Company",
		"More Numbers?",
		"Add Numbers",
		"Portout Pin",
		"Bill",
		"Schedule",
		"Business Name",
		"Address",
		"Plans",
		"Add Number Packages",
		"AddOns",
		"Signature",
		// "Terms",
		"Payment",
		"Order Summary",
	]

	const dispatch = useDispatch();

	const history = useHistory();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const continueOrder = queryParams.get('continue-order');

	const prevStep = useRef();

	const [sidebarStatus, setSidebarStatus] = useState(false)
	const [audio, setAudio] = useState(false)
	const [activeStep, setActiveStep] = useState(0)
	const [customExisiting, setCustomExisiting] = useState(null)
	const [maxStepReached, setMaxStepReached] = useState(0)
	const [activeFunnel, setFunnel] = useState(0)
	const [isSticky, setIsSticky] = useState(false)
	// const [tipEnable, setTipEnable] = useState(true);
	// Scroll sticky sidebar
	window.onscroll = function () { myFunction() };

	var header = document.getElementById("public-sidebar");
	var sticky = header?.offsetTop;
	function myFunction() {
		if (window.scrollY > 87) {
			setIsSticky(true)
		} else {
			setIsSticky(false)
		}
	}

	const setStep = (e) => {
		if (e >= 0) {
			if (e > maxStepReached) {
				setMaxStepReached(e);
			}
			setActiveStep(e);
			//   console.log("previous step count " + prevStep.current)
		}
	}

	const handleKeyDown = (event) => {
		// if (event.key === 'Enter') {
		// 	setStep(activeStep + 1)
		// }
	}

	const markupForStepsCounter = () => {
		const maxSteps = activeFunnel ? 5 : 22;
		const stepTitle = activeFunnel ? NewTitle : ExistingTitle;
		let stepsCounterMarkup = [];

		for (let index = 1; index < maxSteps; index++) {
			stepsCounterMarkup.push(
				// <li title={stepTitle[index]} className={(index == activeStep ? "active " : index < activeStep ? "accessable active " : maxStepReached >= index ? "accessable " : "") + "navigationItem elaboration bottom"} onClick={() => index == activeStep ? setStep(index) : index < activeStep ? setStep(index) : maxStepReached >= index ? setStep(index) : ""}></li>
				<li
					key={index}
					data-title={stepTitle[index] + " (" + index + ")"}
					className={(index === activeStep ? "active " : index < activeStep ? "accessable active " : maxStepReached >= index ? "accessable " : "") + "navigationItem elaboration bottom"}
				// eslint-disable-next-line no-loop-func
				//   onClick={() => (index === activeStep) ? setStep(index) : index < activeStep ? setStep(index) : maxStepReached >= index ? setStep(index) : ""}
				>
				</li>
			);
		}
		return stepsCounterMarkup;
	};

	const removeQueryParam = () => {
		queryParams.delete('continue-order');
		history.replace({
			pathname: location.pathname,
			search: queryParams.toString(),
		});
		setCustomExisiting({})
	};

	useEffect(() => {
		//assign the ref's current value to the count Hook
		const orderStepData = async () => {
			await dispatch(OrderStepDataById(continueOrder)).then(({ bool, result }) => {
				if (bool) {
					setCustomExisiting(result.state_body)
					setActiveStep(result?.step_id + 1)
					removeQueryParam()
				}
			}).catch(err => console.log(err.message))
		}

		if (continueOrder) {
			orderStepData()
		}
		prevStep.current = activeStep;
	}, [activeStep]);
	return (
		<>
			<Title title={"Order Form"} />
			<Topbar />
			<div className="stepsnavigation">
				{markupForStepsCounter()}
			</div>
			<div className={(audio ? '' : 'disabled ') + 'audioToggle'} onClick={() => setAudio(!audio)} >
				<i className={audio ? 'fa fa-volume-up' : 'fa fa-volume-off'}></i>
			</div>
			<div className={sidebarStatus ? "sidebar-toggle active" : "sidebar-toggle"} onClick={() => setSidebarStatus(!sidebarStatus)} title="Form History" >
				<i className={!sidebarStatus ? "fa fa-chevron-right" : "fa fa-chevron-left"} aria-hidden="true"></i>
			</div>
			<ExistingNumberProvider>
				{/* <NewNumberProvider> */}
					<div id='public-sidebar' className={(sidebarStatus ? "public-sidebar " : "public-sidebar collapsed") + ' ' + (isSticky ? "sticky" : "")}>
						{sidebarStatus &&
							<h4 className='text-primary-c mb-4'><i className="fa fa-history"></i> Form History</h4>
						}
						{sidebarStatus &&
							<NumberSidebarData activeStep={activeStep} setStep={setStep} prevStep={prevStep.current} activeFunnel={activeFunnel} />
						}
					</div>
					<div className="order_body">
						{/* <img className='order_body_image'  src="/static/media/bg-image.4bb606f6.jpg" alt="bg" height={'100%'} width={'100%'} /> */}
						{/* <TheForm sidebarStatus={sidebarStatus} handleKeyDown={handleKeyDown} activeStep={activeStep} setStep={setStep} setFunnel={setFunnel} activeFunnel={activeFunnel} prevStep={prevStep.current}  /> */}
						<div id={"example-advanced-form"} action="#" className={(sidebarStatus ? "slideRight " : "") + "main wizard clearfix step-" + activeStep} onKeyDown={handleKeyDown}>

							{activeStep === 0 &&
								<Initial activeStep={activeStep} setStep={setStep} setFunnel={setFunnel} activeFunnel={activeFunnel} />
							}
							{(activeFunnel === 0 && activeStep !== 0) &&
								<ExistingNumber activeStep={activeStep} setStep={setStep} prevStep={prevStep.current} customInitialState={customExisiting} />
							}
							{/* {(activeFunnel === 1 && activeStep !== 0) &&
								// <NewNumber />
							} */}
						</div>
						{/* {
							tipEnable &&
							<div className="tipWrapper">
								<div className="tipModule">
									<div className="tipClose" onClick={() => setTipEnable(false)}>x</div>
									<div className="resume_modal_wrapper">
										<div className="row">
											<div className="col-12 text-center">
												<div className="continue_form_text pb-3">
													<img alt='' src="/images/forms/order-delivery.png" width="120" />
													<h4>Good News! We have saved your previous order submission state.</h4>
													<h5 className="orange mb-4">Take a look at the details you provided below</h5>
													<div className="sidebarData-popup">
														<p>Preference: <b>Existing Number</b></p><p>Phone Number: <b>+1 (565) 394-3133</b></p><p>Verification: <b>Text Message</b></p><p>Mobile Number: <b>YES</b></p><p>Keep as Mobile: <b>YES</b></p><p>Only Fax: <b>NO</b></p><p>Carrier: <b>At &amp; T Wireless</b></p><p>Account Number: <b>8528</b></p><p>Is this number BTN: <b>YES</b></p><p>Port More Numbers: <b>NO</b></p>
													</div>
													<p className="fnt-5 blue mt-4 mb-3">Do you want to resume with your previously filled data?</p>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<div className="continue_form_buttons1">
													<a href="#" className="" onClick={() => setTipEnable(false)}>
														Start Over
													</a>
												</div>
											</div>
											<div className="col-6">
												<div className="continue_form_buttons2" onClick={() => setTipEnable(false)}>
													<a href="#" className="button">
														Use This Data
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						} */}
					</div>
				{/* </NewNumberProvider> */}
			</ExistingNumberProvider>
			<Footer />
		</>
	)
}

export default Order
