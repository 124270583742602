import ValidationError from 'components/Order/ValidationError';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import '../assets/css/country-select.scoped.css';
import { useHistory } from 'react-router-dom';
import { NewNumberContext } from 'contexts/FormsContext';
import { formatPhone, formatPhoneByCountry } from 'general/FormatNumber';
import { useSelector } from 'react-redux';

const NumberResult = () => {
    const { NewNumberData, setNewNumberData } = useContext(NewNumberContext);
    const { formState: { errors }, handleSubmit, register, setValue, watch } = useForm({
        defaultValues: {
            selected_numbers: NewNumberData?.selected_numbers || [],
        }
    });
    const history = useHistory();
    const previousStepUrl = '/new-number-order/select-number';
    const nextStepUrl = '/new-number-order/plans';
    const [loading, setLoading] = useState(false);
    const availableNumbers = useSelector(state => state.newNumber.availableNumbers)

    const selectedNumbers = watch("selected_numbers", NewNumberData.selected_numbers || []);

    // Toggle selection of numbers
    const handleSelect = (number) => {
        let updatedSelection = [...selectedNumbers];

        if (updatedSelection.includes(number)) {
            // Remove the number if already selected
            updatedSelection = updatedSelection.filter((n) => n !== number);
        } else {
            // Add the number if not already selected
            updatedSelection.push(number);
        }

        // Update form state
        setValue("selected_numbers", updatedSelection, { shouldValidate: true });
    };

    const submitHandler = (data) => {
        setLoading(true);
        setNewNumberData({ ...NewNumberData, selected_numbers: data.selected_numbers });
        history.push(nextStepUrl);
        setLoading(false);
    };
    return (
        <>
            <ValidationError errors={errors} />
            <div className="step">
                <form onSubmit={handleSubmit(submitHandler)}>
                    <h5 className="mb-2 sm-heading">Select Number(s)</h5>
                    <h2 className="lg-heading mb-5">Select the numbers that you want to order.</h2>

                    <div className="radio-group row justify-content-center align-items-center px-3 text-center">
                        {availableNumbers.map((available_number) => (
                            <div
                                key={available_number}
                                role="button"
                                tabIndex={0}
                                onClick={() => handleSelect(available_number)}
                                onKeyDown={(e) => e.key === 'Enter' && handleSelect(available_number)}
                                className={`col-3 m-2 text-center rounded radio bg-white ${selectedNumbers.includes(available_number) ? "selected" : ""
                                    }`}
                                style={{ cursor: "pointer" }}
                            >
                                <div className="country-card text-center p-2">
                                    <div className="country-name mt-2">{formatPhoneByCountry(available_number)}</div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Hidden input for selected_numbers */}
                    <input
                        type="hidden"
                        {...register("selected_numbers", {
                            validate: (value) => value.length > 0 || "At least one number must be selected.",
                        })}
                    />

                    {errors.selected_numbers && (
                        <p className="text-danger">{errors.selected_numbers.message}</p>
                    )}

                    {/* Footer Buttons */}
                    <div className="footer d-flex flex-column align-items-center w-100 mt-4">
                        <button
                            type="submit"
                            className="proceed-btn mb-2"
                            disabled={loading}
                        >
                            {loading ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                "Proceed"
                            )}
                        </button>

                        <div className="navigation-buttons">
                            <button
                                type="button"
                                onClick={() => history.push(previousStepUrl)}
                                className="prev-step elaboration"
                                data-title="Previous"
                            >
                                <img src="/images/forms/icon-prev.png" alt="Previous" />
                            </button>

                            <button
                                type="submit"
                                className="next-step elaboration"
                                data-title="Next"
                                disabled={loading}
                            >
                                <img src="/images/forms/icon-next.png" alt="Next" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default NumberResult;
