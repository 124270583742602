import { GET_COUNTRIES, GET_NUMBERS } from "redux/actions/NewNumberAction"

const initialState = {
    countryList: [],
    availableNumbers: []
}

const NewNumberReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COUNTRIES:
            return {
                ...state,
                countryList: [...action.payload]
            }
        case GET_NUMBERS:
            return {
                ...state,
                availableNumbers: [...action.payload]
            }
        default:
            return { ...state }

    }
}

export default NewNumberReducer
