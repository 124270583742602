import Coverage from './Coverage';
import Order from '../order/Order';
import SignupVerify from './SignupVerify';
import NewNumber from 'pages/order/NewNumber';


const formRoutes = [
    {
        path: '/order',
        component: Order,
    },
    {
        path: '/coverage',
        component: Coverage,
    },
    {
        path: '/signup-verify',
        component: SignupVerify,
    },
    {
        path: '/new-number-order',
        component: NewNumber,
    },
];

export default formRoutes
